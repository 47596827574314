import React, { FC } from 'react';
import { DimensionValue } from 'react-native';
import { useTheme } from '../theme/useTheme';
import { Block, Image, Text, TextButton } from '../atoms';

interface Props {
  subtitle?: string;
  image: string;
  type: 'vertical' | 'horizontal' | 'fullImageWidth';
  title?: string;
  link?: { text: string; onclick?: () => void };
  children?: React.ReactNode;
}

const _ItemCard: FC<Props> = ({ subtitle, image, title, type, link, children }) => {
  const { icons, sizes, assets } = useTheme();

  const isHorizontal = type === 'horizontal';
  const viewWidth = sizes.width - sizes.padding * 2;

  const cardWidth: Record<Props['type'], DimensionValue> = {
    horizontal: '100%',
    vertical: (viewWidth - sizes.sm) / 2,
    fullImageWidth: '100%',
  };

  const imageStyle: Record<Props['type'], any> = {
    horizontal: { width: sizes.width / 2.435, height: 114 },
    vertical: { width: '100%', height: 110 },
    fullImageWidth: { width: '100%', height: 200 },
  };

  return (
    <Block card row={isHorizontal} width={cardWidth[type]}>
      {subtitle && (
        <Text p secondary align="right" marginBottom={sizes.s}>
          {subtitle}
        </Text>
      )}
      <Image
        resizeMode="cover"
        source={image ? { uri: image } : assets.imageNotAvailable}
        style={imageStyle[type]}
      />
      <Block
        flex={1}
        paddingTop={sizes.s}
        justify="space-between"
        paddingLeft={isHorizontal ? sizes.sm : 0}
        paddingBottom={isHorizontal ? sizes.s : 0}
      >
        {title && (
          <Text p marginBottom={sizes.s}>
            {title}
          </Text>
        )}
        {link && (
          <TextButton text={link.text} icon={icons.arrow} onPress={link.onclick} />
        )}
        {children}
      </Block>
    </Block>
  );
};

export const ItemCard = React.memo(_ItemCard);
