import React, { FC, useCallback } from 'react';
import { Alert } from 'react-native';
import { useTranslation } from '../theme/useTranslation';
import { useTheme } from '../theme/useTheme';
import { Block } from '../atoms';
import { ImageHeader } from '../organisms';
import { AuthenticationFormTemplate } from '../templates';
import { RegisterUserVM } from '../../ports/primary/view-models/register-user/registerUserVM';
import {
  RegistrationInputs,
  RegistrationInputsError,
} from '../../ports/primary/ui/hooks/register-screen/useRegisterScreen.state';

interface Props {
  registerUserVM: RegisterUserVM;
  formData: RegistrationInputs;
  formErrors: RegistrationInputsError;
  onFormDataChange: (value: Partial<RegistrationInputs>) => void;
  onRegister: () => Promise<void>;
  onGoogleSignUp: () => void;
}

const _RegisterPage: FC<Props> = ({
  registerUserVM,
  formData,
  formErrors,
  onFormDataChange,
  onRegister,
  onGoogleSignUp,
}) => {
  const { sizes } = useTheme();
  const { t } = useTranslation();

  const handleSignUp = useCallback(async () => {
    if (!Object.values(formErrors).includes(false)) {
      return onRegister();
    }
    Alert.alert(t('common.fillAllFields'));
  }, [formErrors, onRegister, t]);

  return (
    <Block safe paddingHorizontal={sizes.s} marginTop={sizes.md}>
      <Block scroll>
        <ImageHeader text={t('register.title')} />

        <AuthenticationFormTemplate
          registration={{
            data: formData,
            inputsError: formErrors,
            handleChange: onFormDataChange,
            handleSignUp,
            handleGoogleSignUp: () => onGoogleSignUp(),
          }}
          isLoading={registerUserVM.isLoading}
        />
      </Block>
    </Block>
  );
};

export const RegisterPage = React.memo(_RegisterPage);
