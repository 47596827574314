import { Announcement } from '../../../../../domain/models/announcement';
import { AnnouncementResponse } from '../types/graphqlAnnouncementGateway.types';

export class AnnouncementAdapter {
  static fromAnnouncementResponse(response: AnnouncementResponse): Announcement {
    return {
      id: response.id,

      timestamp: new Date(response.publishedAt),
      // TODO: set default image
      image: response.imageUri ?? '',
      make: response.makeName,
      model: response.modelName,
      year: response.fabricationYear,
      city: response.cityName,

      price: response.price,
      currency: 'MAD',
      link: response.publicUri,

      fuel: response.fuelType,
      horsePower: response.horsePower,
      kilometers: response.mileage,

      isFeatured: response.isFeatured,
    };
  }
}
