import { gql } from '@apollo/client';
import { ME_FRAGMENT } from '../queries/getMe.query';

export const LOGIN_USER = gql`
  mutation login($user: LoginUserDto!) {
    logIn(user: $user) {
      token
      ${ME_FRAGMENT}
    }
  }
`;
