import { AnnouncementGateway } from '../../../../../domain/gateways/announcementGateway';
import { Announcement } from '../../../../../domain/models/announcement';
import { AnnouncementAdapter } from '../adapters/announcementAdapter';
import { AnnouncementResponse } from '../types/graphqlAnnouncementGateway.types';
import { ApolloClient } from './apolloClient';
import { ALL_ANNOUNCEMENTS } from './queries/allAnnouncements.query';

export class GraphqlAnnouncementGateway implements AnnouncementGateway {
  async retrieveAnnouncements(): Promise<Announcement[]> {
    const client = await ApolloClient.getInstance();
    const { data } = await client.mutate<{
      allMatchingOffers: AnnouncementResponse[] | null;
    }>({ mutation: ALL_ANNOUNCEMENTS });

    return (
      data?.allMatchingOffers?.map(AnnouncementAdapter.fromAnnouncementResponse) ?? []
    );
  }
}
