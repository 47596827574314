import {
  Action,
  Store,
  ThunkAction,
  ThunkDispatch,
  configureStore,
} from '@reduxjs/toolkit';
import { AppState } from './appState';
import { authenticationPerformerReducer } from '../domain/reducers/authenticationPerformer.reducer';
import { LocalStorageProvider } from '../domain/gateways/localStorageProvider';
import { AuthenticationGateway } from '../domain/gateways/authenticationGateway';
import { AnnouncementGateway } from '../domain/gateways/announcementGateway';
import { announcementsRetrievalReducer } from '../domain/reducers/announcementsRetrieval.reducer';
import { OAuthProvider } from '../domain/gateways/providers/oAuthProvider';
import { alertsReducer } from '../domain/reducers/alerts.reducer';
import { AlertGateway } from '../domain/gateways/alertGateway';
import { VehicleSpecGateway } from '../domain/gateways/vehicleSpecGateway';
import { vehicleSpecsReducer } from '../domain/reducers/vehicleSpecs.reducer';
import { CacheProvider } from '../domain/gateways/providers/cacheProvider';
import { CityGateway } from '../domain/gateways/cityGateway';
import { citiesReducer } from '../domain/reducers/cities.reducer';
import { currentAnnouncementReducer } from '../domain/reducers/currentAnnouncement.reducer';
import { notificationReducer } from '../domain/reducers/notification.reducer';
import { NotificationGateway } from '../domain/gateways/notificationGateway';

export interface AppDependencies {
  localStorageProvider: LocalStorageProvider;
  authenticationGateway: AuthenticationGateway;
  announcementGateway: AnnouncementGateway;
  alertGateway: AlertGateway;
  notificationGateway: NotificationGateway;
  vehicleSpecGateway: VehicleSpecGateway;
  cityGateway: CityGateway;
  cacheProvider: CacheProvider;
  oAuthProvider: OAuthProvider;
}

export const initReduxStore = (
  dependencies: Partial<AppDependencies>,
  preloadedState?: Partial<AppState>,
) => {
  return configureStore({
    reducer: {
      authentication: authenticationPerformerReducer,
      vehicleSpecs: vehicleSpecsReducer,
      cities: citiesReducer,
      announcements: announcementsRetrievalReducer,
      currentAnnouncement: currentAnnouncementReducer,
      alerts: alertsReducer,
      notification: notificationReducer,
    },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
        thunk: { extraArgument: dependencies },
      }),
    preloadedState: preloadedState as AppState,
    devTools: true,
  });
};

export type AppDispatch = ThunkDispatch<AppState, AppDependencies, Action>;

export type ReduxStore = Store<AppState> & { dispatch: AppDispatch };

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  AppDependencies,
  Action
>;

export type AppSelector<T> = (state: AppState) => T;
