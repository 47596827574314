import { NotificationGateway } from '../../../../../domain/gateways/notificationGateway';
import { ApolloClient } from './apolloClient';
import { SAVE_NOTIFICATION_TOKEN } from './mutations/saveNotificationToken.mutation';
import { UPDATE_ENABLING_NOTIFICATIONS_STATUS } from './mutations/updateEnablingNotificationsStatus.mutation';

export class GraphqlNotificationGateway implements NotificationGateway {
  async saveToken(token: string): Promise<boolean> {
    try {
      const client = await ApolloClient.getInstance();
      const { data } = await client.mutate<{
        saveNotificationToken: boolean | null;
      }>({
        mutation: SAVE_NOTIFICATION_TOKEN,
        variables: { token },
      });

      return data?.saveNotificationToken ?? false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async updateEnablingStatus(): Promise<void> {
    const client = await ApolloClient.getInstance();
    await client.mutate({
      mutation: UPDATE_ENABLING_NOTIFICATIONS_STATUS,
    });
  }
}
