import React, { useCallback, useState } from 'react';
import { Alert } from 'react-native';
import {
  DrawerContentComponentProps,
  DrawerContentScrollView,
} from '@react-navigation/drawer';
import { useSelector } from 'react-redux';
import { useTranslation } from '../theme/useTranslation';
import { useTheme } from '../theme/useTheme';
import { Block, Switch, Text, NavButton } from '../atoms';
import { Divider, Logo } from '../molecules';
import { selectProfile } from '../../ports/primary/view-models/profile/profileVM.selectors';
import { useEnv } from '../../ports/primary/ui/hooks/useEnv';

// TODO: see why using VM
export const DrawerMenuContent = ({
  navigation,
  ...rest
}: DrawerContentComponentProps) => {
  const { t } = useTranslation();
  const { isDev } = useEnv();
  const [active, setActive] = useState('Home');
  const { icons, colors, sizes } = useTheme();

  const profileVM = useSelector(selectProfile);

  const handleNavigation = useCallback(
    (to: string) => {
      setActive(to);
      navigation.navigate(to);
    },
    [navigation, setActive],
  );

  const screens = [
    { name: t('screens.home'), to: 'Home', icon: icons.home },
    ...(isDev
      ? [{ name: t('screens.components'), to: 'Components', icon: icons.components }]
      : []),
    { name: t('screens.alerts'), to: 'MyAlerts', icon: icons.bell },
    ...(profileVM
      ? [{ name: t('screens.profile'), to: 'Profile', icon: icons.profile }]
      : []),
    ...(profileVM
      ? []
      : [{ name: t('screens.register'), to: 'Register', icon: icons.register }]),
  ];

  return (
    <DrawerContentScrollView
      {...rest}
      scrollEnabled
      removeClippedSubviews
      renderToHardwareTextureAndroid
      contentContainerStyle={{ paddingBottom: sizes.padding }}
    >
      <Block paddingHorizontal={sizes.padding}>
        <Logo />

        <Divider />

        {screens.map(_ => (
          <NavButton
            key={_.name}
            icon={_.icon}
            screenName={_.name}
            isActive={active === _.to}
            onPress={() => handleNavigation(_.to)}
          />
        ))}

        <Divider />

        {isDev && (
          <Block row justify="space-between">
            <Text color={colors.text}>{t('theme.darkMode')}</Text>
            <Switch
              checked={false}
              onPress={() => Alert.alert(t('theme.darkModeNotAvailable'))}
            />
          </Block>
        )}
      </Block>
    </DrawerContentScrollView>
  );
};
