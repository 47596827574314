import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../../../../store/appState';
import { CurrentAnnouncementVM } from './currentAnnouncementVM';
import { AppSelector } from '../../../../store/reduxStore';
import { AnnouncementAdapter } from '../../adapters/announcement.adapter';

const selector = (state: AppState) => state;

export const selectCurrentAnnouncement: AppSelector<CurrentAnnouncementVM> =
  createSelector([selector], (state: AppState) => {
    const { announcement, isLoading } = state.currentAnnouncement;

    return {
      announcement: announcement ? AnnouncementAdapter.toUi(announcement) : null,
      isLoading,
    };
  });
