import { AppThunk } from '../../../store/reduxStore';
import {
  setAnnouncements,
  setLoadingAnnouncements,
} from '../../reducers/announcementsRetrieval.reducer';

export const retrieveAnnouncements =
  (): AppThunk => async (dispatch, getState, dependencies) => {
    const loadedAnnouncements = getState().announcements.announcements;

    if (!loadedAnnouncements?.length) dispatch(setLoadingAnnouncements());

    const announcements = await dependencies.announcementGateway.retrieveAnnouncements();

    dispatch(setAnnouncements(announcements));
  };
