import { AppThunk } from '../../../store/reduxStore';
import { NotificationProvider } from '../../models/notificationProvider';
import { setNotificationToken } from '../../reducers/notification.reducer';

export const saveNotificationToken =
  (token: string, provider: NotificationProvider): AppThunk =>
  async (dispatch, getState, dependencies) => {
    if (getState().notification.token === token) return;

    const isSuccess = await dependencies.notificationGateway.saveToken(token, provider);

    if (!isSuccess) return;

    dispatch(setNotificationToken(token));
    console.info('save token: ', token);
  };
