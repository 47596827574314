import React, { FC, useState } from 'react';
import RNSlider from '@react-native-community/slider';
import { useTheme } from '../theme/useTheme';

interface Props {
  minimumValue: number;
  maximumValue: number;
  step?: number;
  value: number;
  onValueChange: (value: number) => void;
}

const _Slider: FC<Props> = ({
  minimumValue,
  maximumValue,
  step = 1,
  value,
  onValueChange,
}) => {
  const { colors } = useTheme();

  /**
   * This state is because the library bug when the value is 0
   */
  const [displayValue, setDisplayVAlue] = useState(value);

  const onValueChangeHandler = (_: number) => {
    onValueChange(_ || 0);
    setDisplayVAlue(_ || 0.001);
  };

  return (
    <RNSlider
      step={step}
      value={displayValue}
      minimumValue={minimumValue}
      maximumValue={maximumValue}
      minimumTrackTintColor={colors.primary as string}
      maximumTrackTintColor={colors.gray as string}
      thumbTintColor={colors.primary as string}
      onValueChange={onValueChangeHandler}
    />
  );
};

export const Slider = React.memo(_Slider);
