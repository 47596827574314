import { AppThunk } from '../../../store/reduxStore';
import { setAuthentication } from '../../reducers/authenticationPerformer.reducer';

export const logoutUser = (): AppThunk => async (dispatch, _getState, dependencies) => {
  // TODO: initialize all store
  await dependencies.localStorageProvider.removeAuthenticatedUser();

  dispatch(setAuthentication(null));
};

// TODO: test connect => see specs => logout => connect => re-see specs
