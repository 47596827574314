import { AppThunk } from '../../../store/reduxStore';
import {
  setCurrentAnnouncement,
  setLoadingCurrentAnnouncement,
} from '../../reducers/currentAnnouncement.reducer';

export const retrieveAnnouncementById =
  (announcementId: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setLoadingCurrentAnnouncement());

    const announcementFromStore = getState().announcements.announcements?.find(
      _ => _.id === announcementId,
    );

    if (announcementFromStore) {
      dispatch(setCurrentAnnouncement(announcementFromStore));
    }
  };
