import { gql } from '@apollo/client';

export const VEHICLE_SPECS = gql`
  query vehicleSpecs {
    vehicleSpecs {
      id
      makeName
      models {
        id
        name
      }
    }
  }
`;
