import { gql } from '@apollo/client';

export const MY_ALERTS = gql`
  query myAlerts {
    myAlerts {
      id
      cityId
      cityName
      cityRadius
      vehicleMakeId
      vehicleMakeName
      vehicleModels {
        id
        name
      }
      minBudget
      maxBudget
    }
  }
`;
