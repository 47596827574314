import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RegisterPage } from '../../../../design-system/pages';
import { selectRegisterUser } from '../../view-models/register-user/registerUserVM.selectors';
import { AppDispatch } from '../../../../store/reduxStore';
import { useGoogleAuth } from '../hooks/useGoogleAuth';
import { registerUser } from '../../../../domain/use-cases/authentication/registerUser';
import { useRegisterScreenState } from '../hooks/register-screen/useRegisterScreen.state';

const _RegisterScreen = () => {
  const dispatch = useDispatch<AppDispatch>();
  const registerUserVM = useSelector(selectRegisterUser);

  const { handleGoogleAuth } = useGoogleAuth();
  const { formData, formErrors, onFormDataChange } = useRegisterScreenState();

  const onRegister = useCallback(async () => {
    dispatch(
      registerUser({
        email: formData.email,
        password: formData.password,
        name: formData.name,
      }),
    );
  }, [dispatch, formData]);

  return (
    <RegisterPage
      registerUserVM={registerUserVM}
      formData={formData}
      formErrors={formErrors}
      onFormDataChange={onFormDataChange}
      onRegister={onRegister}
      onGoogleSignUp={handleGoogleAuth}
    />
  );
};

export const RegisterScreen = React.memo(_RegisterScreen);
