/* eslint-disable global-require */
import { Dimensions, Platform } from 'react-native';
import {
  ICommonTheme,
  ThemeAssets,
  ThemeFonts,
  ThemeIcons,
  ThemeLineHeights,
  ThemeSizes,
  ThemeSpacing,
  ThemeWeights,
} from './theme.types';
import OpenSansLight from '../../../assets/fonts/OpenSans-Light.ttf';
import OpenSansRegular from '../../../assets/fonts/OpenSans-Regular.ttf';
import OpenSansSemiBold from '../../../assets/fonts/OpenSans-SemiBold.ttf';
import OpenSansBold from '../../../assets/fonts/OpenSans-Bold.ttf';
import OpenSansExtraBold from '../../../assets/fonts/OpenSans-ExtraBold.ttf';
import logo from '../../../assets/images/logo.png';
import header from '../../../assets/images/header.png';
import background from '../../../assets/images/background.png';
import defaultCard from '../../../assets/images/default-card.png';
import defaultAvatar from '../../../assets/images/default-avatar.png';
import defaultPhoto from '../../../assets/images/default-photo.png';
import imageNotAvailable from '../../../assets/images/image-not-available.jpg';
import crown from '../../../assets/images/crown.png';
import avito from '../../../assets/images/providers/avito.png';
import moteur from '../../../assets/images/providers/moteur.png';

const { width, height } = Dimensions.get('window');

export const WEIGHTS: ThemeWeights = {
  text: 'normal',
  h1: Platform.OS === 'ios' ? '700' : 'normal',
  h2: Platform.OS === 'ios' ? '700' : 'normal',
  h3: Platform.OS === 'ios' ? '700' : 'normal',
  h4: Platform.OS === 'ios' ? '700' : 'normal',
  h5: Platform.OS === 'ios' ? '600' : 'normal',
  p: 'normal',

  thin: Platform.OS === 'ios' ? '100' : 'normal',
  extralight: Platform.OS === 'ios' ? '200' : 'normal',
  light: Platform.OS === 'ios' ? '300' : 'normal',
  normal: Platform.OS === 'ios' ? '400' : 'normal',
  medium: Platform.OS === 'ios' ? '500' : 'normal',
  semibold: Platform.OS === 'ios' ? '600' : 'normal',
  bold: Platform.OS === 'ios' ? '700' : 'normal',
  extrabold: Platform.OS === 'ios' ? '800' : 'normal',
  black: Platform.OS === 'ios' ? '900' : 'normal',
};

export const ICONS: ThemeIcons = {
  apple: require('../../../assets/icons/apple.png'),
  google: require('../../../assets/icons/google.png'),
  facebook: require('../../../assets/icons/facebook.png'),
  arrow: require('../../../assets/icons/arrow.png'),
  articles: require('../../../assets/icons/articles.png'),
  basket: require('../../../assets/icons/basket.png'),
  bell: require('../../../assets/icons/bell.png'),
  calendar: require('../../../assets/icons/calendar.png'),
  chat: require('../../../assets/icons/chat.png'),
  check: require('../../../assets/icons/check.png'),
  clock: require('../../../assets/icons/clock.png'),
  close: require('../../../assets/icons/close.png'),
  components: require('../../../assets/icons/components.png'),
  document: require('../../../assets/icons/document.png'),
  documentation: require('../../../assets/icons/documentation.png'),
  extras: require('../../../assets/icons/extras.png'),
  flight: require('../../../assets/icons/flight.png'),
  home: require('../../../assets/icons/home.png'),
  hotel: require('../../../assets/icons/hotel.png'),
  image: require('../../../assets/icons/image.png'),
  location: require('../../../assets/icons/location.png'),
  menu: require('../../../assets/icons/menu.png'),
  more: require('../../../assets/icons/more.png'),
  notification: require('../../../assets/icons/notification.png'),
  office: require('../../../assets/icons/office.png'),
  payment: require('../../../assets/icons/payment.png'),
  profile: require('../../../assets/icons/profile.png'),
  register: require('../../../assets/icons/register.png'),
  rental: require('../../../assets/icons/rental.png'),
  search: require('../../../assets/icons/search.png'),
  settings: require('../../../assets/icons/settings.png'),
  star: require('../../../assets/icons/star.png'),
  train: require('../../../assets/icons/train.png'),
  trash: require('../../../assets/icons/trash.png'),
  users: require('../../../assets/icons/users.png'),
  warning: require('../../../assets/icons/warning.png'),
};

export const ASSETS: ThemeAssets = {
  /* fonts */
  OpenSansLight,
  OpenSansRegular,
  OpenSansSemiBold,
  OpenSansBold,
  OpenSansExtraBold,

  /* backgrounds/logo */
  logo,
  header,
  background,

  defaultCard,
  defaultAvatar,
  defaultPhoto,
  imageNotAvailable,

  crown,

  providerLogos: { avito, moteur },
};

export const FONTS: ThemeFonts = {
  text: 'OpenSans-Regular',
  h1: 'OpenSans-Bold',
  h2: 'OpenSans-Bold',
  h3: 'OpenSans-Bold',
  h4: 'OpenSans-Bold',
  h5: 'OpenSans-SemiBold',
  p: 'OpenSans-Regular',

  thin: 'OpenSans-Light',
  extralight: 'OpenSans-Light',
  light: 'OpenSans-Light',
  normal: 'OpenSans-Regular',
  medium: 'OpenSans-SemiBold',
  semibold: 'OpenSans-SemiBold',
  bold: 'OpenSans-Bold',
  extrabold: 'OpenSans-ExtraBold',
  black: 'OpenSans-ExtraBold',
};

export const LINE_HEIGHTS: ThemeLineHeights = {
  text: 22,
  h1: 60,
  h2: 55,
  h3: 43,
  h4: 33,
  h5: 24,
  p: 22,
};

export const SIZES: ThemeSizes = {
  base: 8,
  text: 14,
  radius: 4,
  padding: 20,

  h1: 44,
  h2: 40,
  h3: 32,
  h4: 24,
  h5: 18,
  p: 16,

  buttonBorder: 1,
  buttonRadius: 8,
  socialSize: 64,
  socialRadius: 16,
  socialIconSize: 26,

  shadowOffsetWidth: 0,
  shadowOffsetHeight: 7,
  shadowOpacity: 0.07,
  shadowRadius: 4,
  elevation: 2,

  inputHeight: 46,
  inputBorder: 1,
  inputRadius: 8,
  inputPadding: 12,

  cardRadius: 16,
  cardPadding: 10,

  imageRadius: 14,
  avatarSize: 32,
  avatarRadius: 8,

  switchWidth: 50,
  switchHeight: 24,
  switchThumb: 20,

  checkboxWidth: 18,
  checkboxHeight: 18,
  checkboxRadius: 5,
  checkboxIconWidth: 10,
  checkboxIconHeight: 8,

  linkSize: 12,

  /** font size multiplier: for maxFontSizeMultiplier prop */
  multiplier: 2,
};

export const SPACING: ThemeSpacing = {
  /** xs: 4px */
  xs: SIZES.base * 0.5,
  /** s: 8px */
  s: SIZES.base * 1,
  /** sm: 16px */
  sm: SIZES.base * 2,
  /** m: 24px */
  m: SIZES.base * 3,
  /** md: 32px */
  md: SIZES.base * 4,
  /** l: 40px */
  l: SIZES.base * 5,
  /** xl: 48px */
  xl: SIZES.base * 6,
  /** xxl: 56px */
  xxl: SIZES.base * 7,
};

export const COMMON_THEME: ICommonTheme = {
  assets: ASSETS,
  icons: ICONS,
  fonts: FONTS,
  weights: WEIGHTS,
  lines: LINE_HEIGHTS,
  sizes: { width, height, ...SIZES, ...SPACING },
};
