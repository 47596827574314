import React from 'react';
import { useSelector } from 'react-redux';
import { createStackNavigator } from '@react-navigation/stack';
import {
  ComponentsScreen,
  HomeScreen,
  RegisterScreen,
  LoginScreen,
  ProfileScreen,
  MyAlertsScreen,
  AnnouncementDetailScreen,
} from '../screens';
import { useScreenOptions } from './useScreenOptions';
import { useTranslation } from '../../../../design-system/theme/useTranslation';
import { selectProfile } from '../../view-models/profile/profileVM.selectors';
import { useEnv } from '../hooks/useEnv';
import { usePushNotifications } from '../hooks/notifications/usePushNotifications';

export type RootStackParamList = {
  Home: undefined;
  Components: undefined;
  Register: undefined;
  Login: undefined;
  Profile: undefined;
  MyAlerts: undefined;
  AnnouncementDetail: { announcementId: string };
};

const Stack = createStackNavigator<RootStackParamList>();

export const Screens = () => {
  const { t } = useTranslation();
  const { isDev } = useEnv();
  const profileVM = useSelector(selectProfile);
  const screenOptions = useScreenOptions();

  usePushNotifications();

  return (
    <Stack.Navigator screenOptions={screenOptions.stack}>
      {!profileVM && (
        <Stack.Screen
          name="Login"
          component={LoginScreen}
          options={{ headerShown: false }}
        />
      )}
      {!profileVM && (
        <Stack.Screen
          name="Register"
          component={RegisterScreen}
          options={{ headerShown: false }}
        />
      )}

      {profileVM && (
        <Stack.Screen
          name="Home"
          component={HomeScreen}
          options={{ title: t('navigation.home') }}
        />
      )}

      {profileVM && (
        <Stack.Screen
          name="AnnouncementDetail"
          component={AnnouncementDetailScreen}
          options={{ headerShown: true }}
        />
      )}

      {profileVM && (
        <Stack.Screen
          name="MyAlerts"
          component={MyAlertsScreen}
          options={{ title: t('navigation.myAlerts') }}
        />
      )}

      {profileVM && (
        <Stack.Screen
          name="Profile"
          component={ProfileScreen}
          options={{ headerShown: false }}
        />
      )}

      {isDev && (
        <Stack.Screen
          name="Components"
          component={ComponentsScreen}
          options={screenOptions.components}
        />
      )}
    </Stack.Navigator>
  );
};
