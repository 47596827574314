import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Storage from '@react-native-async-storage/async-storage';

import { IUseData } from '../constants/types';

import { lightTheme } from './lightTheme';
import { ITheme } from './theme.types';

export const DataContext = React.createContext({});

// TODO: try to remove this provider
export const DataProvider = ({ children }: { children: React.ReactNode }) => {
  const [isDark, setIsDark] = useState(false);
  const [theme, setTheme] = useState<ITheme>(lightTheme);

  const getIsDark = useCallback(async () => {
    const isDarkJSON = await Storage.getItem('isDark');

    if (isDarkJSON !== null) {
      setIsDark(JSON.parse(isDarkJSON));
    }
  }, [setIsDark]);

  const handleIsDark = useCallback(
    (payload: boolean) => {
      setIsDark(payload);
      void Storage.setItem('isDark', JSON.stringify(payload));
    },
    [setIsDark],
  );

  useEffect(() => void getIsDark(), [getIsDark]);

  useEffect(() => {
    setTheme(isDark ? lightTheme : lightTheme);
  }, [isDark]);

  const contextValue = useMemo(
    () => ({
      isDark,
      handleIsDark,
      theme,
      setTheme,
    }),
    [isDark, theme, setTheme, handleIsDark],
  );

  return <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>;
};

export const useData = () => useContext(DataContext) as IUseData;
