import { Announcement } from '../../../models/announcement';

export const announcementsMock: Announcement[] = [
  {
    id: '1',
    image: 'https://picsum.photos/200/300',
    make: 'BMW',
    model: 'X5',
    year: 2015,
    fuel: 'Diesel',
    horsePower: 300,
    price: 20000,
    currency: 'EUR',
    city: 'Bucharest',
    kilometers: 100000,
    timestamp: new Date('2020-01-01T13:00:00.000Z'),
    link: 'https://www.example.com',
    isFeatured: true,
  },
  {
    id: '2',
    image: 'https://picsum.photos/200/300',
    make: 'BMW',
    model: 'X4',
    year: 2015,
    fuel: 'Diesel',
    horsePower: 300,
    price: 20000,
    currency: 'EUR',
    city: 'Bucharest',
    kilometers: 100000,
    timestamp: new Date('2020-01-01T13:00:00.000Z'),
    link: 'https://www.example.com',
    isFeatured: false,
  },
  {
    id: '3',
    image: 'https://picsum.photos/200/300',
    make: 'BMW',
    model: 'X3',
    year: 2015,
    fuel: 'Diesel',
    horsePower: 300,
    price: 20000,
    currency: 'EUR',
    city: 'Bucharest',
    kilometers: 100000,
    timestamp: new Date('2020-01-01T13:00:00.000Z'),
    link: 'https://www.example.com',
    isFeatured: false,
  },
  {
    id: '4',
    image: 'https://picsum.photos/200/300',
    make: 'BMW',
    model: 'X2',
    year: 2015,
    fuel: 'Diesel',
    horsePower: 300,
    price: 20000,
    currency: 'EUR',
    city: 'Bucharest',
    kilometers: 100000,
    timestamp: new Date('2020-01-01T13:00:00.000Z'),
    link: 'https://www.example.com',
    isFeatured: true,
  },
  {
    id: '5',
    image: 'https://picsum.photos/200/300',
    make: 'BMW',
    model: 'X1',
    year: 2015,
    fuel: 'Diesel',
    horsePower: 300,
    price: 20000,
    currency: 'EUR',
    city: 'Bucharest',
    kilometers: 100000,
    timestamp: new Date('2020-01-01T13:00:00.000Z'),
    link: 'https://www.example.com',
    isFeatured: true,
  },
];
