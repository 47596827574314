import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppState } from '../../store/appState';
import { Announcement } from '../models/announcement';
import { setAuthentication } from './authenticationPerformer.reducer';

const initialState: AppState['announcements'] = {
  announcements: null,
  isLoading: false,
  error: null,
};

const _setLoadingAnnouncements = (state: AppState['announcements']) => {
  state.isLoading = true;
};

const _setAnnouncements = (
  state: AppState['announcements'],
  action: PayloadAction<Announcement[]>,
) => {
  state.announcements = action.payload;
  state.isLoading = false;
};

const announcementsRetrievalSlice = createSlice({
  name: 'announcementsRetrieval',
  initialState,
  reducers: {
    setLoadingAnnouncements: _setLoadingAnnouncements,
    setAnnouncements: _setAnnouncements,
  },
  extraReducers(builder) {
    builder.addCase(setAuthentication, (state, action) => {
      if (action.payload === null) {
        return initialState;
      }
      return state;
    });
  },
});

export const { setLoadingAnnouncements, setAnnouncements } =
  announcementsRetrievalSlice.actions;

export const { reducer: announcementsRetrievalReducer } = announcementsRetrievalSlice;
