import React, { FC } from 'react';
import { Platform } from 'react-native';
import { useTheme } from '../../theme/useTheme';
import { Button } from './Button';
import { Text } from '../Text';

const isAndroid = Platform.OS === 'android';

interface Props {
  text: string;
  isLoading?: boolean;
  disabled?: boolean;
  outlined?: boolean;
  fullWidth?: boolean;
  minHeight?: number;
  onPress?: () => void;
}

const _PrimaryButton: FC<Props> = ({
  text,
  outlined,
  minHeight,
  fullWidth = false,
  ...props
}) => {
  const { sizes } = useTheme();
  return (
    <Button
      {...(fullWidth && { flex: 1 })}
      primary
      shadow={!isAndroid}
      minHeight={minHeight}
      outlined={outlined}
      {...props}
    >
      <Text
        padding={sizes.s}
        white={!outlined}
        primary={outlined}
        bold
        transform="uppercase"
      >
        {text}
      </Text>
    </Button>
  );
};

export const PrimaryButton = React.memo(_PrimaryButton);
