import { Alert } from '../../../../../domain/models/alert';
import { CreateAlertRequest, MyAlertsResponse } from '../types/graphqlAlertGateway.types';

export class AlertAdapter {
  static toCreateAlertRequest(alert: Alert): CreateAlertRequest {
    return {
      id: alert.id,
      cityId: alert.city.id,
      cityRadius: alert.cityRadius,
      vehicleMakeId: alert.vehicleMake.id,
      vehicleModelIds: alert.vehicleModels.map(_ => _.id),
      minBudget: alert.minBudget,
      maxBudget: alert.maxBudget,
    };
  }

  static fromMyAlertsResponse(response: MyAlertsResponse): Alert {
    return {
      id: response.id,
      city: {
        id: response.cityId,
        name: response.cityName,
      },
      cityRadius: response.cityRadius,
      vehicleMake: {
        id: response.vehicleMakeId,
        name: response.vehicleMakeName,
      },
      vehicleModels: response.vehicleModels,
      minBudget: response.minBudget,
      maxBudget: response.maxBudget,
    };
  }
}
