import React, { FC } from 'react';
import { Block, Text } from '../atoms';
import { useTheme } from '../theme/useTheme';

interface Props {
  vertical?: boolean;
  text?: string;
}

const _Divider: FC<Props> = ({ vertical, text }) => {
  const { sizes, gradients } = useTheme();

  return vertical ? (
    <Block gray width={1} marginHorizontal={sizes.sm} height={sizes.socialIconSize} />
  ) : (
    <Block row align="center" justify="center" marginVertical={sizes.sm}>
      <Block
        height={1.5}
        width="50%"
        end={[1, 0]}
        start={[0, 1]}
        gradient={gradients.divider}
      />
      {text && <Text marginHorizontal={sizes.s}>{text}</Text>}
      <Block
        height={1.5}
        width="50%"
        end={[0, 1]}
        start={[1, 0]}
        gradient={gradients.divider}
      />
    </Block>
  );
};

export const Divider = React.memo(_Divider);
