import { gql } from '@apollo/client';
import { ME_FRAGMENT } from '../queries/getMe.query';

export const O_AUTH_USER = gql`
  mutation oAuthUser($user: OAuthUserDto!) {
    oAuthUser(user: $user) {
      token
      ${ME_FRAGMENT}
    }
  }
`;
