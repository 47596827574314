import { VehicleSpecGateway } from '../../../../domain/gateways/vehicleSpecGateway';
import { VehicleSpec } from '../../../../domain/models/vehicle/vehicleSpec';
import { inMemoryLoading } from './inMemoryLoading';

export class InMemoryVehicleSpecGateway implements VehicleSpecGateway {
  private _vehicleSpecs: VehicleSpec[] = [];

  private _itShouldFail = false;

  async retrieveAll(): Promise<VehicleSpec[]> {
    await inMemoryLoading();

    if (this._itShouldFail) {
      throw new Error('error');
    }

    return this._vehicleSpecs;
  }

  set itShouldFail(value: boolean) {
    this._itShouldFail = value;
  }

  feedWith(...vehicleSpecs: VehicleSpec[]): void {
    this._vehicleSpecs = vehicleSpecs;
  }
}
