import React, { FC, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StackScreenProps } from '@react-navigation/stack';
import { AnnouncementDetailPage } from '../../../../design-system/pages';
import { AppDispatch } from '../../../../store/reduxStore';
import { RootStackParamList } from '../navigation/Screens';
import { retrieveAnnouncementById } from '../../../../domain/use-cases/announcements-retrieval/retrieveAnnouncementById';
import { selectCurrentAnnouncement } from '../../view-models/current-announcement/currentAnnouncementVM.selectors';
import { setCurrentAnnouncement } from '../../../../domain/reducers/currentAnnouncement.reducer';

type Props = StackScreenProps<RootStackParamList, 'AnnouncementDetail'>;

const _AnnouncementDetailScreen: FC<Props> = ({ route }) => {
  const dispatch = useDispatch<AppDispatch>();

  const { announcementId } = route.params;

  const vm = useSelector(selectCurrentAnnouncement);

  useLayoutEffect(() => {
    dispatch(retrieveAnnouncementById(announcementId));

    return () => {
      dispatch(setCurrentAnnouncement(null));
    };
  }, [announcementId, dispatch]);

  return (
    <AnnouncementDetailPage isLoading={vm.isLoading} announcement={vm.announcement} />
  );
};

export const AnnouncementDetailScreen = React.memo(_AnnouncementDetailScreen);
