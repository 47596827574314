import moment from 'moment';
import { Announcement } from '../../../domain/models/announcement';
import { UiAnnouncement } from '../ui/types/UiAnnouncement';

export class AnnouncementAdapter {
  static toUi(announcement: Announcement): UiAnnouncement {
    return {
      id: announcement.id,
      publishedAt: moment(announcement.timestamp).fromNow(),
      image: announcement.image,
      title: `${announcement.make} ${announcement.model} • ${announcement.year} • ${announcement.city}`,
      fuel: announcement.fuel,
      horsePower: announcement.horsePower?.toString(),
      kilometers: `${announcement.kilometers} km`,
      link: announcement.link,
      price: announcement.price ? `${announcement.price} ${announcement.currency}` : '',
      isFeatured: announcement.isFeatured,
    };
  }
}
