import React, { FC, useCallback } from 'react';
import { Platform, Pressable, ViewStyle } from 'react-native';

import { useTheme } from '../theme/useTheme';
import { ISpacing } from '../theme/theme.types';
import { Image } from './Image';
import { Block } from './Block';

interface Props extends ISpacing {
  id?: string;
  checked: boolean;
  onCheck?: (checked: boolean) => void;
  style?: ViewStyle;
}

const _Checkbox: FC<Props> = ({ onCheck, checked, id = 'Checkbox', ...props }) => {
  const { colors, icons, sizes } = useTheme();

  const handlePress = useCallback(() => {
    onCheck?.(!checked);
  }, [checked, onCheck]);

  const checkboxID =
    Platform.OS === 'android' ? { accessibilityLabel: id } : { testID: id };

  return (
    <Pressable {...checkboxID} hitSlop={sizes.s} onPress={handlePress}>
      <Block
        align="center"
        justify="center"
        gray={!checked}
        outlined={!checked}
        width={sizes.checkboxWidth}
        height={sizes.checkboxHeight}
        radius={sizes.checkboxRadius}
        gradient={checked ? colors.checkbox : undefined}
        {...props}
      >
        {true && (
          <Image
            source={icons.check}
            color={colors.checkboxIcon}
            width={sizes.checkboxIconWidth}
            height={sizes.checkboxIconHeight}
          />
        )}
      </Block>
    </Pressable>
  );
};

export const Checkbox = React.memo(_Checkbox);
