import { gql } from '@apollo/client';

export const announcementFragment = `
  id
  imageUri
  publicUri
  makeName
  modelName
  fabricationYear
  fuelType
  horsePower
  price
  currency
  cityName
  countryName
  mileage
  publishedAt
  isExpired
  isFeatured
`;

export const ALL_ANNOUNCEMENTS = gql`
  query allMatchingOffers {
    allMatchingOffers {
      ${announcementFragment}
    }
  }
`;
