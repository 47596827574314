import React, { FC, useCallback, useRef } from 'react';
import { FlatList } from 'react-native';
import { useTheme } from '../theme/useTheme';
import { Block, Text, PrimaryButton } from '../atoms';
import { useTranslation } from '../theme/useTranslation';
import { CreateAlertCard } from '../organisms';
import { CreateAlertInput } from '../../ports/primary/ui/types/CreateAlertInput';
import { CreateAlertError } from '../../ports/primary/ui/hooks/create-alert-screen/useCreateAlertScreen.state';
import { List } from '../molecules';
import { VehicleSpec } from '../../domain/models/vehicle/vehicleSpec';
import { City } from '../../domain/models/city';

interface Props {
  isLoading: boolean;
  canAddAlert: boolean;
  vehicleSpecs: VehicleSpec[];
  cities: City[];
  alerts: CreateAlertInput[];
  errors: CreateAlertError[];
  onChangeAlert: (id: string, alert: Partial<CreateAlertInput>) => void;
  deleteAlert: (id: string) => void;
  addAlert: () => void;
  onSave: () => void;
  isLoadingSave: boolean;
}

const _MyAlertsPage: FC<Props> = ({
  isLoading,
  canAddAlert,
  vehicleSpecs,
  cities,
  alerts,
  errors,
  addAlert,
  deleteAlert,
  onChangeAlert,
  onSave,
  isLoadingSave,
}) => {
  const { t } = useTranslation();
  const { colors, sizes } = useTheme();

  const scrollViewRef = useRef<FlatList | undefined>();

  const onAddAlert = useCallback(() => {
    addAlert();
    setTimeout(() => {
      scrollViewRef?.current?.scrollToEnd({ animated: true });
    }, 50);
  }, [addAlert, scrollViewRef]);

  return (
    <Block flex={1} gap={sizes.s} color={colors.background} padding={sizes.padding}>
      <Text bold center>
        {t('myAlerts.title')}
      </Text>

      <List
        innerRef={scrollViewRef}
        loading={isLoading}
        data={alerts}
        emptyListText={t('myAlerts.emptyList')}
        renderItem={({ item }) => (
          <CreateAlertCard
            alert={item}
            vehicleSpecs={vehicleSpecs}
            cities={cities}
            error={errors.find(_ => _.id === item.id) || null}
            onChangeAlert={(newAlert: Partial<CreateAlertInput>) =>
              onChangeAlert(item.id, newAlert)
            }
            onDeleteAlert={() => deleteAlert(item.id)}
          />
        )}
      />

      {!isLoading && (
        <Block row gap={sizes.sm}>
          <PrimaryButton
            disabled={!canAddAlert || isLoading}
            fullWidth
            outlined
            text={t('myAlerts.addAlert')}
            onPress={onAddAlert}
          />
          <PrimaryButton
            disabled={isLoading}
            fullWidth
            text={t('common.save')}
            onPress={onSave}
            isLoading={isLoadingSave}
          />
        </Block>
      )}
    </Block>
  );
};

export const MyAlertsPage = React.memo(_MyAlertsPage);
