import { AlertGateway } from '../../../../domain/gateways/alertGateway';
import { Alert } from '../../../../domain/models/alert';
import { inMemoryLoading } from './inMemoryLoading';

export class InMemoryAlertGateway implements AlertGateway {
  private _nextCreatedAlerts: Alert[] = [];

  private _itShouldFail = false;

  async retrieveCreatedAlerts() {
    await inMemoryLoading();

    if (this._itShouldFail) {
      throw new Error('Failed to retrieve alerts');
    }
    return this._nextCreatedAlerts;
  }

  async saveAlerts(alerts: Alert[]): Promise<boolean> {
    await inMemoryLoading();

    if (this._itShouldFail) {
      throw new Error('Failed to save alerts');
    }
    this._nextCreatedAlerts = alerts;
    return true;
  }

  set nextCreatedAlerts(alerts: Alert[]) {
    this._nextCreatedAlerts = alerts;
  }

  set itShouldFail(shouldFail: boolean) {
    this._itShouldFail = shouldFail;
  }
}
