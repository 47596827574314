import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { I18n, Scope, TranslateOptions } from 'i18n-js';
import * as Localization from 'expo-localization';
import Storage from '@react-native-async-storage/async-storage';

import { ITranslate } from '../constants/types';
import translations from '../constants/translations';

// TODO: include it to store
const i18n = new I18n(translations);

const defaultLocale = 'en';

/**
 * Set the locale once at the beginning of your app.
 */
i18n.locale = defaultLocale;
/*
 * Set the key-value pairs for the different languages you want to support.
 */
i18n.translations = translations;
// When a value is missing from a language it'll fallback to another language with the key present.
i18n.enableFallback = true;

export const TranslationContext = React.createContext({});

export const TranslationProvider = ({ children }: { children: React.ReactNode }) => {
  const [locale, setLocale] = useState(defaultLocale);

  const t = useCallback(
    (scope: Scope, options?: TranslateOptions) => {
      return i18n.t(scope, { ...options, locale });
    },
    [locale],
  );

  const getLocale = useCallback(async () => {
    setLocale(Localization.getLocales()[0].languageCode || defaultLocale);
  }, [setLocale]);

  useEffect(() => void getLocale(), [getLocale]);

  useEffect(() => {
    i18n.locale = locale;
    void Storage.setItem('locale', locale);
  }, [locale]);

  const contextValue = useMemo(() => ({ t, locale, setLocale }), [t, locale, setLocale]);

  return (
    <TranslationContext.Provider value={contextValue}>
      {children}
    </TranslationContext.Provider>
  );
};

/*
 * useTranslation hook based on i18n-js
 * Source: https://github.com/fnando/i18n-js
 */
export const useTranslation = () => useContext(TranslationContext) as ITranslate;
