import { Platform } from 'react-native';
import { AppDependencies } from './store/reduxStore';
import { DeterministicLocalStorageProvider } from './ports/secondary/gateways/in-memory/deterministicLocalStorageProvider';
import { InMemoryAuthenticationGateway } from './ports/secondary/gateways/in-memory/inMemoryAuthenticationGateway';
import { registerUserCommandMock } from './domain/use-cases/specs/mocks/registerUserCommandMock';
import { MobileLocalStorageProvider } from './ports/secondary/gateways/real/MobileLocalStorageProvider';
import { GraphqlAuthenticationGateway } from './ports/secondary/gateways/real/http/graphqlAuthenticationGateway';
import { WebLocalStorageProvider } from './ports/secondary/gateways/real/WebLocalStorageProvider';
import { ApolloClient } from './ports/secondary/gateways/real/http/apolloClient';
import { InMemoryAnnouncementGateway } from './ports/secondary/gateways/in-memory/inMemoryAnnouncementGateway';
import { announcementsMock } from './domain/use-cases/specs/mocks/announcementsMock';
import { InMemoryAlertGateway } from './ports/secondary/gateways/in-memory/inMemoryAlertGateway';
import { alertsMock } from './domain/use-cases/specs/mocks/alertsMock';
import { HttpOAuthProvider } from './ports/secondary/gateways/real/http/httpOAuthProvider';
import { GraphqlAlertGateway } from './ports/secondary/gateways/real/http/graphqlAlertGateway';
import { GraphqlAnnouncementGateway } from './ports/secondary/gateways/real/http/graphqlAnnouncementGateway';
import { InMemoryCacheProvider } from './ports/secondary/gateways/in-memory/inMemoryCacheProvider';
import { InMemoryVehicleSpecGateway } from './ports/secondary/gateways/in-memory/inMemoryVehicleSpecGateway';
import { WebCacheProvider } from './ports/secondary/gateways/real/webCacheProvider';
import { MobileCacheProvider } from './ports/secondary/gateways/real/mobileCacheProvider';
import { authenticatedUserMock } from './domain/use-cases/specs/mocks/authenticatedUserMock';
import { GraphqlVehicleSpecGateway } from './ports/secondary/gateways/real/http/graphqlVehicleSpecGateway';
import { InMemoryCityGateway } from './ports/secondary/gateways/in-memory/inMemoryCityGateway';
import { GraphqlCityGateway } from './ports/secondary/gateways/real/http/graphqlCityGateway';
import { citiesMock } from './domain/use-cases/specs/mocks/citiesMock';
import { vehicleSpecsMock } from './domain/use-cases/specs/mocks/vehicleSpecsMock';
import { InMemoryNotificationGateway } from './ports/secondary/gateways/in-memory/inMemoryNotificationGateway';
import { GraphqlNotificationGateway } from './ports/secondary/gateways/real/http/graphqlNotificationGateway';

export const injectDependencies = async (
  mode: 'inMemory' | 'http',
): Promise<AppDependencies> => {
  switch (mode) {
    case 'inMemory':
      return inMemoryDependencies();
    case 'http':
      return httpDependencies();
  }
};

const inMemoryDependencies = async (): Promise<AppDependencies> => {
  const localStorageProvider = new DeterministicLocalStorageProvider();
  const authenticationGateway = new InMemoryAuthenticationGateway();
  const announcementGateway = new InMemoryAnnouncementGateway();
  const oAuthProvider = new HttpOAuthProvider();
  const alertGateway = new InMemoryAlertGateway();

  authenticationGateway.registeredUsers = [registerUserCommandMock];

  announcementGateway.feedWith(...announcementsMock);
  alertGateway.nextCreatedAlerts = alertsMock;

  /**
   * To authenticate the user
   */
  const authenticatedUser = authenticatedUserMock;
  await localStorageProvider.setAuthenticatedUser(authenticatedUser);
  authenticationGateway.nextAuthenticatedUser = authenticatedUser;

  const cityGateway = new InMemoryCityGateway();
  cityGateway.feedWith(...citiesMock);

  const vehicleSpecGateway = new InMemoryVehicleSpecGateway();
  vehicleSpecGateway.feedWith(...vehicleSpecsMock);

  return {
    localStorageProvider,
    authenticationGateway,
    announcementGateway,
    oAuthProvider,
    alertGateway,
    vehicleSpecGateway,
    cacheProvider: new InMemoryCacheProvider(),
    cityGateway,
    notificationGateway: new InMemoryNotificationGateway(),
  };
};

const httpDependencies = async (): Promise<AppDependencies> => {
  const platform = Platform.OS;
  const localStorageProvider =
    platform === 'web' ? new WebLocalStorageProvider() : new MobileLocalStorageProvider();
  const cacheProvider =
    platform === 'web' ? new WebCacheProvider() : new MobileCacheProvider();

  // await localStorageProvider.removeAuthenticatedUser();

  ApolloClient.setGetToken(
    async () => (await localStorageProvider.getAuthenticatedUser())?.token || null,
  );

  return {
    localStorageProvider,
    authenticationGateway: new GraphqlAuthenticationGateway(),
    announcementGateway: new GraphqlAnnouncementGateway(),
    oAuthProvider: new HttpOAuthProvider(),
    alertGateway: new GraphqlAlertGateway(),
    cacheProvider,
    vehicleSpecGateway: new GraphqlVehicleSpecGateway(),
    cityGateway: new GraphqlCityGateway(),
    notificationGateway: new GraphqlNotificationGateway(),
  };
};
