import { AppThunk } from '../../../store/reduxStore';
import { updateCurrentUser } from '../../reducers/authenticationPerformer.reducer';

export const updateNotificationsEnablingStatus =
  (newStatus: boolean): AppThunk =>
  async (dispatch, _getState, dependencies) => {
    try {
      await dependencies.notificationGateway.updateEnablingStatus();

      dispatch(updateCurrentUser({ isNotificationsEnabled: newStatus }));
    } catch (error) {
      console.error('error updating notification status ', error);
    }
  };
