import React, { FC } from 'react';
import { DrawerActions, useNavigation } from '@react-navigation/native';
import { ColorValue } from 'react-native';
import { Button } from './buttons/Button';
import { Image } from './Image';
import { useTheme } from '../theme/useTheme';

interface Props {
  color?: ColorValue;
}

const _BurgerMenu: FC<Props> = ({ color }) => {
  const navigation = useNavigation();
  const { icons, colors } = useTheme();

  return (
    <Button onPress={() => navigation.dispatch(DrawerActions.toggleDrawer())}>
      <Image
        source={icons.menu}
        width={16}
        height={16}
        radius={0}
        color={color || colors.white}
      />
    </Button>
  );
};

export const BurgerMenu = React.memo(_BurgerMenu);
