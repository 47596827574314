import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProfilePage } from '../../../../design-system/pages';
import { selectProfile } from '../../view-models/profile/profileVM.selectors';
import { AppDispatch } from '../../../../store/reduxStore';
import { logoutUser } from '../../../../domain/use-cases/authentication/logoutUser';
import { updateNotificationsEnablingStatus } from '../../../../domain/use-cases/notifications/updateNotificationsEnablingStatus';

const _ProfileScreen = () => {
  const dispatch = useDispatch<AppDispatch>();
  const profileVM = useSelector(selectProfile);

  const [isNotificationEnabled, onIsNotificationEnabledChange] = useState(
    !!profileVM?.isNotificationsEnabled,
  );

  const onLogout = useCallback(() => {
    dispatch(logoutUser());
  }, [dispatch]);

  const onChangeEnableNotifications = useCallback(
    (newStatus: boolean) => {
      onIsNotificationEnabledChange(newStatus);
      dispatch(updateNotificationsEnablingStatus(newStatus));
    },
    [dispatch],
  );

  return (
    <ProfilePage
      profileVM={profileVM}
      onLogout={onLogout}
      isNotificationEnabled={isNotificationEnabled}
      onChangeEnableNotifications={onChangeEnableNotifications}
    />
  );
};

export const ProfileScreen = React.memo(_ProfileScreen);
