import React, { useCallback } from 'react';
import { FlatList, ListRenderItem, View } from 'react-native';
import { SpinnerLoader, Text } from '../atoms';
import { useTheme } from '../theme/useTheme';
import { ItemWithId } from '../types/itemWithId';

interface Props<T> {
  data: T[];
  renderItem: ListRenderItem<T>;
  loading?: boolean;
  emptyList?: React.ReactNode;
  emptyListText?: string;
  px?: number;
  py?: number;
  onRefresh?: () => void;
  innerRef?: React.MutableRefObject<any>;
  loader?: React.ReactNode;
}

const _List = <T extends ItemWithId>({
  data,
  renderItem,
  loading = false,
  emptyList,
  emptyListText = 'data is empty',
  px = 0,
  py = 0,
  onRefresh,
  innerRef,
  loader,
}: Props<T>) => {
  const { sizes } = useTheme();

  const BlankVerticalSeparator = useCallback(
    () => <View style={{ height: sizes.sm }} />,
    [sizes.sm],
  );

  const _emptyList = useCallback(
    () => emptyList || <Text>{emptyListText}</Text>,
    [emptyList, emptyListText],
  );

  if (loading) {
    return loader || <SpinnerLoader />;
  }

  return (
    <FlatList
      ref={innerRef}
      removeClippedSubviews={false}
      data={data}
      renderItem={renderItem}
      ListEmptyComponent={_emptyList}
      keyExtractor={item => item.id}
      ItemSeparatorComponent={BlankVerticalSeparator}
      contentContainerStyle={{ paddingVertical: py, paddingHorizontal: px }}
      showsVerticalScrollIndicator={false}
      onRefresh={onRefresh}
      refreshing={loading}
      ListFooterComponent={<View style={{ height: 20 }} />}
    />
  );
};

export const List = React.memo(_List) as typeof _List;
