import React from 'react';
import { Text } from './Text';
import { ItemWithId } from '../types/itemWithId';
import { SelectItem, SelectTemplate } from './SelectTemplate';

interface Props<T> {
  label: string | null;
  options: SelectItem<T>[];
  onSelectValue: (value: T) => void;
  placeholder: string;
  danger?: boolean;
  disabled?: boolean;
}

const _Select = <T extends ItemWithId>({
  label,
  options,
  onSelectValue,
  placeholder,
  danger,
  disabled,
}: Props<T>) => {
  return (
    <SelectTemplate
      label={label}
      options={options}
      onSelectValue={onSelectValue}
      placeholder={placeholder}
      closeOnSelect
      danger={danger}
      disabled={disabled}
      itemRenderer={item => (
        <Text transform="capitalize" p center>
          {item.label}
        </Text>
      )}
    />
  );
};

export const Select = React.memo(_Select) as typeof _Select;
