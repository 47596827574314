import { createSelector } from '@reduxjs/toolkit';
import { ProfileVM } from './profileVM';
import { AppState } from '../../../../store/appState';
import { AuthenticatedUser } from '../../../../domain/models/authenticatedUser';
import { AppSelector } from '../../../../store/reduxStore';

const selector = (state: AppState) => state.authentication.authenticatedUser;

export const selectProfile: AppSelector<ProfileVM | null> = createSelector(
  [selector],
  (authenticatedUser: AuthenticatedUser | null) => authenticatedUser?.user ?? null,
);
