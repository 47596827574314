import React, { FC, useCallback, useMemo } from 'react';
import { Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useTheme } from '../theme/useTheme';
import { Block, Text, IconButton, Input, PrimaryButton, LabeledCheckbox } from '../atoms';
import { Divider } from '../molecules';
import { useTranslation } from '../theme/useTranslation';
import {
  RegistrationInputs,
  RegistrationInputsError,
} from '../../ports/primary/ui/hooks/register-screen/useRegisterScreen.state';
import {
  LoginInputs,
  LoginInputsError,
} from '../../ports/primary/ui/hooks/login-screen/useLoginScreen.state';

const isAndroid = Platform.OS === 'android';

interface Props {
  registration?: {
    data: RegistrationInputs;
    handleChange: (value: Partial<RegistrationInputs>) => void;
    inputsError: RegistrationInputsError;
    handleSignUp: () => void;
    handleGoogleSignUp: () => void;
  };
  login?: {
    data: LoginInputs;
    handleChange: (value: Partial<LoginInputs>) => void;
    inputsError: LoginInputsError;
    handleSignIn: () => void;
    handleGoogleSignIn: () => void;
  };
  isLoading: boolean;
}

const _AuthenticationFormTemplate: FC<Props> = ({ registration, login, isLoading }) => {
  const { t } = useTranslation();
  const { icons, colors, sizes } = useTheme();
  const { navigate } = useNavigation();

  const isRegistration = useMemo(() => Boolean(registration), [registration]);

  const isValid = isRegistration ? registration!.inputsError : login!.inputsError;
  const data = isRegistration ? registration!.data : login!.data;

  const handleChange = useMemo(
    () => (isRegistration ? registration!.handleChange : login!.handleChange),
    [isRegistration, registration, login],
  );

  const onPrimaryButtonClick = useMemo(
    () => (isRegistration ? registration!.handleSignUp : login!.handleSignIn),
    [isRegistration, registration, login],
  );

  const onSecondaryButtonClick = useCallback(
    () => navigate(isRegistration ? 'Login' : 'Register'),
    [isRegistration, navigate],
  );

  const onGoogleButtonClick = useMemo(
    () => (isRegistration ? registration!.handleGoogleSignUp : login!.handleGoogleSignIn),
    [isRegistration, registration, login],
  );

  return (
    <Block
      keyboard
      behavior={!isAndroid ? 'padding' : 'height'}
      marginTop={-(sizes.height * 0.2 - sizes.l)}
    >
      <Block
        blur
        intensity={90}
        radius={sizes.sm}
        marginHorizontal="8%"
        shadow={!isAndroid}
        overflow="hidden"
        tint={colors.blurTint}
        paddingVertical={sizes.sm}
      >
        <Text p semibold center>
          {registration ? t('register.subtitle') : t('login.subtitle')}
        </Text>

        <Block row center justify="space-evenly" marginVertical={sizes.m}>
          <IconButton icon={icons.google} onPress={onGoogleButtonClick} />
        </Block>

        <Divider text={t('common.or')} />

        <Block paddingHorizontal={sizes.sm} gap={sizes.m}>
          {registration && (
            <Input
              autoCapitalize="none"
              label={t('common.name')}
              placeholder={t('common.namePlaceholder')}
              success={Boolean(registration.data.name && registration.inputsError.name)}
              danger={Boolean(registration.data.name && !registration.inputsError.name)}
              onChangeText={value => handleChange({ name: value })}
            />
          )}
          <Input
            autoCapitalize="none"
            label={t('common.email')}
            keyboardType="email-address"
            placeholder={t('common.emailPlaceholder')}
            success={Boolean(data.email && isValid.email)}
            danger={Boolean(data.email && !isValid.email)}
            onChangeText={value => handleChange({ email: value })}
          />
          <Input
            secureTextEntry
            autoCapitalize="none"
            label={t('common.password')}
            placeholder={t('common.passwordPlaceholder')}
            onChangeText={value => handleChange({ password: value })}
            success={Boolean(data.password && isValid.password)}
            danger={Boolean(data.password && !isValid.password)}
          />

          {registration && (
            <LabeledCheckbox
              checked={registration.data.agreed}
              onChange={agreed => registration.handleChange({ agreed })}
              label={t('common.agree')}
              link={{
                text: t('common.terms'),
                url: 'https://www.creative-tim.com/terms',
              }}
            />
          )}

          <PrimaryButton
            isLoading={isLoading}
            onPress={onPrimaryButtonClick}
            text={isRegistration ? t('common.signup') : t('common.signin')}
            fullWidth
          />
          <PrimaryButton
            disabled={isLoading}
            outlined
            onPress={onSecondaryButtonClick}
            text={isRegistration ? t('common.signin') : t('common.signup')}
            fullWidth
          />
        </Block>
      </Block>
    </Block>
  );
};

export const AuthenticationFormTemplate = React.memo(_AuthenticationFormTemplate);
