import React, { FC } from 'react';
import { Text } from './Text';

interface Props {
  children: React.ReactNode;
  white?: boolean;
}

const _ScreenHeaderTitle: FC<Props> = ({ white = true, children }) => {
  return (
    <Text p semibold white={white}>
      {children}
    </Text>
  );
};

export const ScreenHeaderTitle = React.memo(_ScreenHeaderTitle);
