import React, { FC } from 'react';
import { StyleSheet, Modal as RNModal, ViewStyle, Platform } from 'react-native';
import { useTheme } from '../theme/useTheme';
import { Block } from './Block';
import { IconButton } from './buttons/IconButton';

interface Props {
  id?: string;
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  style?: ViewStyle;
}

const _Modal: FC<Props> = ({ id = 'Modal', isOpen, children, style, onClose }) => {
  const { icons, colors, sizes } = useTheme();
  const modalStyles = StyleSheet.flatten([style]) as ViewStyle;

  const modalID = Platform.OS === 'android' ? { accessibilityLabel: id } : { testID: id };

  return (
    <RNModal
      {...modalID}
      visible={isOpen}
      style={modalStyles}
      animationType="slide"
      presentationStyle="pageSheet"
      onRequestClose={onClose}
    >
      <Block flex={1} color={colors.white} paddingTop={sizes.xl}>
        <IconButton
          icon={icons.close}
          size={sizes.sm}
          color={colors.primary}
          style={{ position: 'absolute', top: 10, right: 5 }}
          onPress={onClose}
        />
        {children}
      </Block>
    </RNModal>
  );
};

export const Modal = React.memo(_Modal);
