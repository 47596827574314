import React, { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MyAlertsPage } from '../../../../design-system/pages';
import { useCreateAlertScreenState } from '../hooks/create-alert-screen/useCreateAlertScreen.state';
import { selectMyAlerts } from '../../view-models/my-alerts/myAlerts.selectors';
import { retrieveCreatedAlerts } from '../../../../domain/use-cases/alerts/retrieveCreatedAlerts';
import { AppDispatch } from '../../../../store/reduxStore';

const _MyAlertsScreen = () => {
  const dispatch = useDispatch<AppDispatch>();
  const myAlertsVM = useSelector(selectMyAlerts);

  const {
    alerts,
    errors,
    onChangeAlert,
    canAddAlert,
    addAlert,
    deleteAlert,
    saveAlerts,
  } = useCreateAlertScreenState(myAlertsVM?.alerts ?? null);

  useLayoutEffect(() => {
    dispatch(retrieveCreatedAlerts());
  }, [dispatch]);

  return (
    <MyAlertsPage
      canAddAlert={canAddAlert}
      isLoading={!!myAlertsVM?.isLoading}
      alerts={alerts}
      errors={errors}
      onChangeAlert={onChangeAlert}
      deleteAlert={deleteAlert}
      addAlert={addAlert}
      onSave={saveAlerts}
      isLoadingSave={!!myAlertsVM?.isLoadingSave}
      vehicleSpecs={myAlertsVM?.vehicleSpecs ?? []}
      cities={myAlertsVM?.cities ?? []}
    />
  );
};

export const MyAlertsScreen = React.memo(_MyAlertsScreen);
