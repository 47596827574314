import { useNavigation } from '@react-navigation/native';
import React, { useLayoutEffect } from 'react';
import { StyleSheet } from 'react-native';
import Animated, {
  SharedValue,
  interpolate,
  useAnimatedStyle,
} from 'react-native-reanimated';
import { BackButton } from '../atoms';
import { useTheme } from '../theme/useTheme';

interface Props {
  bgContentHeight: number;
  scrollOffset: SharedValue<number>;
  headerRight?: React.ReactNode;
}

export const useTransparentHeader = ({
  bgContentHeight,
  scrollOffset,
  headerRight,
}: Props) => {
  const navigation = useNavigation();
  const { colors } = useTheme();

  const headerAnimatedStyle = useAnimatedStyle(() => {
    return {
      opacity: interpolate(scrollOffset.value, [0, bgContentHeight / 1.5], [0, 1]),
    };
  }, []);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: '',
      headerTransparent: true,
      headerBackground: () => (
        <Animated.View style={[headerAnimatedStyle, styles.header]} />
      ),
      headerRight: () => headerRight,
      headerLeft: () => <BackButton color={colors.primary} />,
      headerRightContainerStyle: { marginRight: 16 },
      headerLeftContainerStyle: { marginLeft: 16 },
    });
  }, [colors.primary, headerAnimatedStyle, headerRight, navigation]);
};

const styles = StyleSheet.create({
  header: {
    backgroundColor: 'white',
    height: 80,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: 'rgba(0, 0, 0, 0.1)',
  },
});
