import { gql } from '@apollo/client';

export const ME_FRAGMENT = `
    id
    name
    email
    isEmailVerified
    picture
    isNotificationsEnabled
`;

export const GET_ME = gql`
  query me {
    me {
      token
      ${ME_FRAGMENT}
    }
  }
`;
