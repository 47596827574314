import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppState } from '../../store/appState';
import { Announcement } from '../models/announcement';
import { setAuthentication } from './authenticationPerformer.reducer';

const initialState: AppState['currentAnnouncement'] = {
  isLoading: false,
  announcement: null,
};

const _setLoadingCurrentAnnouncement = (state: AppState['currentAnnouncement']) => {
  state.isLoading = true;
  state.announcement = null;
};

const _setCurrentAnnouncement = (
  state: AppState['currentAnnouncement'],
  action: PayloadAction<Announcement | null>,
) => {
  state.isLoading = false;
  state.announcement = action.payload;
};

const currentAnnouncementSlice = createSlice({
  name: 'currentAnnouncement',
  initialState,
  reducers: {
    setLoadingCurrentAnnouncement: _setLoadingCurrentAnnouncement,
    setCurrentAnnouncement: _setCurrentAnnouncement,
  },
  extraReducers(builder) {
    builder.addCase(setAuthentication, (state, action) => {
      if (action.payload === null) {
        return initialState;
      }
      return state;
    });
  },
});

export const { setLoadingCurrentAnnouncement, setCurrentAnnouncement } =
  currentAnnouncementSlice.actions;

export const currentAnnouncementReducer = currentAnnouncementSlice.reducer;
