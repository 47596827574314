import React, { FC } from 'react';
import { Block, Text } from '../atoms';
import { useTheme } from '../theme/useTheme';

interface Props {
  label: string;
  children: React.ReactNode;
}

const _LabeledBlock: FC<Props> = ({ label, children }) => {
  const { sizes } = useTheme();

  return (
    <Block gap={sizes.s}>
      <Text bold>{label}</Text>
      {children}
    </Block>
  );
};

export const LabeledBlock = React.memo(_LabeledBlock);
