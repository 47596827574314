import React from 'react';
import { CardStyleInterpolators } from '@react-navigation/stack';
import { useNavigation } from '@react-navigation/native';
import { StackHeaderOptions } from '@react-navigation/stack/lib/typescript/src/types';
import { HeaderTitleProps } from '@react-navigation/elements';

import { useTranslation } from '../../../../design-system/theme/useTranslation';
import { useTheme } from '../../../../design-system/theme/useTheme';
import {
  Button,
  Block,
  Image,
  ScreenHeaderTitle,
  BurgerMenu,
} from '../../../../design-system/atoms';
import { Badge } from '../../../../design-system/molecules';

export const useScreenOptions = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const { icons, colors, sizes } = useTheme();

  const menu = {
    headerStyle: { elevation: 0 },
    headerTitleAlign: 'left',
    cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
    headerTitle: ({ children }: HeaderTitleProps) => (
      <ScreenHeaderTitle white={false}>{children}</ScreenHeaderTitle>
    ),
    headerLeft: () => <BurgerMenu color={colors.icon} />,
    headerRight: () => (
      <Block marginRight={sizes.padding}>
        <Badge icon={icons.bell} onPress={() => Promise.resolve()} />
      </Block>
    ),
  } as StackHeaderOptions;

  const options = {
    stack: menu,
    components: {
      ...menu,
      headerTitle: () => (
        <ScreenHeaderTitle>{t('navigation.components')}</ScreenHeaderTitle>
      ),
      headerRight: () => null,
      headerLeft: () => <BurgerMenu />,
    },
    pro: {
      ...menu,
      headerTransparent: true,
      headerTitle: () => <ScreenHeaderTitle>{t('navigation.pro')}</ScreenHeaderTitle>,
      headerRight: () => null,
      headerLeft: () => <BurgerMenu />,
    },
    back: {
      ...menu,
      headerRight: () => null,
      // TODO: refactor this
      headerLeft: () => (
        <Button onPress={() => navigation.goBack()}>
          <Image
            radius={0}
            width={10}
            height={18}
            color={colors.icon}
            source={icons.arrow}
            transform={[{ rotate: '180deg' }]}
          />
        </Button>
      ),
    },
  };

  return options;
};
