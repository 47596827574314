import React from 'react';
import { Block, Image, Text } from '../atoms';
import { useTheme } from '../theme/useTheme';
import { useTranslation } from '../theme/useTranslation';

const _Logo = () => {
  const { t } = useTranslation();
  const { assets, colors, sizes } = useTheme();

  return (
    <Block row align="center" gap={sizes.sm} marginVertical={sizes.sm}>
      <Image
        radius={0}
        width={sizes.md}
        height={sizes.md}
        color={colors.text}
        source={assets.logo}
      />
      <Block>
        <Text size={sizes.text} semibold>
          {t('app.name')}
        </Text>
      </Block>
    </Block>
  );
};

export const Logo = React.memo(_Logo);
