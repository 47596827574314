import React, { FC } from 'react';

import { View, StyleSheet } from 'react-native';

import { FF_SHARE_ANNOUNCEMENT_ENABLED } from '@env';
import { Block, Chip, PrimaryButton, ShareButton, SpinnerLoader, Text } from '../atoms';
import { ImageHeaderTemplate } from '../templates';
import { useTranslation } from '../theme/useTranslation';
import { useInAppBrowser } from '../../ports/primary/ui/hooks/useInAppBrowser';
import { useTheme } from '../theme/useTheme';
import { UiAnnouncement } from '../../ports/primary/ui/types/UiAnnouncement';

interface Props {
  isLoading: boolean;
  announcement: UiAnnouncement | null;
}

const _AnnouncementDetailPage: FC<Props> = ({ announcement, isLoading }) => {
  const { t } = useTranslation();
  const { openLink } = useInAppBrowser();
  const { colors, sizes } = useTheme();

  if (isLoading || !announcement) {
    return <SpinnerLoader />;
  }

  const chipInfos = {
    fuel: announcement.fuel,
    kilometers: `${announcement.kilometers}`,
    horsePower: `${announcement.horsePower} ${t('announcement.horsePower')}`,
  };

  const headerRight = FF_SHARE_ANNOUNCEMENT_ENABLED ? (
    <View style={styles.queue}>
      <ShareButton
        color={colors.primary}
        shareUrl={announcement.link}
        shareName={announcement.title}
      />
    </View>
  ) : undefined;

  return (
    <ImageHeaderTemplate
      imageUri={announcement.image}
      footer={
        <PrimaryButton
          text={t('announcement.accessOffer')}
          onPress={openLink(announcement.link)}
        />
      }
      headerRight={headerRight}
    >
      <Block gap={sizes.sm}>
        <Text h5>{announcement.title}</Text>

        <Block row align="center" gap={sizes.s}>
          {Object.entries(chipInfos).map(([key, value]) => {
            return value ? <Chip key={key} text={value} /> : null;
          })}
        </Block>

        <Block row justify="space-between" align="center">
          <Text h4>{announcement.price ?? t('announcement.priceNotAvailable')}</Text>
        </Block>
      </Block>
    </ImageHeaderTemplate>
  );
};

export const AnnouncementDetailPage = React.memo(_AnnouncementDetailPage);

const styles = StyleSheet.create({
  container: { gap: 20 },
  queue: { flexDirection: 'row', gap: 10 },
  infos: { gap: 6 },
});
