import React, { FC } from 'react';
import { ActivityIndicator, ImageSourcePropType } from 'react-native';
import { useTheme } from '../../theme/useTheme';
import { Block } from '../Block';
import { Image } from '../Image';

interface Props {
  badge: ImageSourcePropType;
}

const _BadgeLoader: FC<Props> = ({ badge }) => {
  const { colors, sizes } = useTheme();

  return (
    <Block card justify="center" align="center" gap={sizes.xs}>
      <Image style={{ height: 40, width: 70 }} source={badge} resizeMode="contain" />
      <ActivityIndicator animating color={colors.primary} />
    </Block>
  );
};

export const BadgeLoader = React.memo(_BadgeLoader);
