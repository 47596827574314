import { ExternalUser } from '../../models/externalUser';

export enum OAuthProviderName {
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
}

export interface OAuthProvider {
  getUser(token: string, provider: OAuthProviderName): Promise<ExternalUser>;
}
