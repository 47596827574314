import React, { useCallback, useMemo, useState } from 'react';
import { Pressable } from 'react-native';
import { useTheme } from '../theme/useTheme';
import { Text } from './Text';
import { Block } from './Block';
import { Image } from './Image';
import { Input } from './Input';
import { Modal } from './Modal';
import { useTranslation } from '../theme/useTranslation';
import { ItemWithId } from '../types/itemWithId';

export interface SelectItem<T> {
  label: string;
  value: T;
}

interface Props<T> {
  label: string | null;
  options: SelectItem<T>[];
  onSelectValue: (value: T) => void;
  itemRenderer: (item: SelectItem<T>) => React.ReactNode;
  placeholder: string;
  closeOnSelect?: boolean;
  danger?: boolean;
  limitShownOptions?: number;
  disabled?: boolean;
}

const _SelectTemplate = <T extends ItemWithId>({
  label,
  options,
  onSelectValue,
  itemRenderer,
  placeholder,
  closeOnSelect = false,
  danger,
  disabled,
  limitShownOptions,
}: Props<T>) => {
  const { colors, sizes, icons } = useTheme();
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();

  const selectColor = useMemo(
    () => (danger ? colors.danger : colors.gray),
    [danger, colors],
  );
  const hasLabel = useMemo(() => !!label, [label]);

  const [searchKey, setSearchKey] = useState('');

  const onOpenModal = useCallback(() => {
    if (!disabled) {
      setShowModal(true);
    }
  }, [disabled]);

  const filteredOptions = useMemo(() => {
    const filtered = options.filter(option =>
      option.label.toLowerCase().startsWith(searchKey.toLowerCase()),
    );

    if (!limitShownOptions) {
      return filtered;
    }

    return filtered.slice(0, limitShownOptions);
  }, [limitShownOptions, options, searchKey]);

  const handleSelectValue = useCallback(
    (value: T) => {
      onSelectValue(value);
      if (closeOnSelect) {
        setShowModal(false);
      }
    },
    [closeOnSelect, onSelectValue],
  );

  return (
    <Pressable
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: sizes.inputHeight,
        flexGrow: 1,
        flexShrink: 1,
        borderRadius: sizes.inputRadius,
        borderWidth: sizes.inputBorder,
        borderColor: selectColor,
        paddingHorizontal: sizes.inputPadding,
      }}
      onPress={onOpenModal}
    >
      <Text transform="capitalize" p color={hasLabel ? undefined : colors.gray}>
        {label || placeholder}
      </Text>
      <Image
        source={icons.arrow}
        color={colors.black}
        transform={[{ rotate: '90deg' }]}
      />
      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <Block flex={1} padding={sizes.sm} gap={sizes.m}>
          <Input
            search
            placeholder={t('common.search')}
            value={searchKey}
            onChangeText={setSearchKey}
          />
          <Block scroll>
            <Block flex={1} gap={sizes.sm}>
              {filteredOptions.map(_ => (
                <Block key={_.label} onPress={() => handleSelectValue(_.value)}>
                  {itemRenderer(_)}
                </Block>
              ))}
            </Block>
          </Block>
        </Block>
      </Modal>
    </Pressable>
  );
};

export const SelectTemplate = React.memo(_SelectTemplate) as typeof _SelectTemplate;
