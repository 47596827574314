import React, { FC, useCallback } from 'react';
import { Alert } from 'react-native';
import { AuthSessionResult } from 'expo-auth-session';
import { Block } from '../atoms';
import { useTheme } from '../theme/useTheme';
import { useTranslation } from '../theme/useTranslation';
import { ImageHeader } from '../organisms';
import { AuthenticationFormTemplate } from '../templates';
import { LoginUserVM } from '../../ports/primary/view-models/login-user/loginUserVM';
import {
  LoginInputs,
  LoginInputsError,
} from '../../ports/primary/ui/hooks/login-screen/useLoginScreen.state';

interface Props {
  loginUserVM: LoginUserVM;
  formData: LoginInputs;
  formErrors: LoginInputsError;
  onFormDataChange: (value: Partial<LoginInputs>) => void;
  onGoogleSignIn: () => Promise<AuthSessionResult>;
  onLogin: () => Promise<void>;
}

const _LoginPage: FC<Props> = ({
  loginUserVM,
  formData,
  formErrors,
  onFormDataChange,
  onLogin,
  onGoogleSignIn,
}) => {
  const { sizes } = useTheme();
  const { t } = useTranslation();

  const handleSignIn = useCallback(async () => {
    if (!Object.values(formErrors).includes(false)) {
      return onLogin();
    }
    Alert.alert(t('common.fillAllFields'));
  }, [formErrors, onLogin, t]);

  return (
    <Block safe paddingHorizontal={sizes.s} marginTop={sizes.md}>
      <Block scroll>
        <ImageHeader text={t('login.title')} />

        <AuthenticationFormTemplate
          login={{
            data: formData,
            inputsError: formErrors,
            handleChange: onFormDataChange,
            handleSignIn,
            handleGoogleSignIn: () => onGoogleSignIn(),
          }}
          isLoading={loginUserVM.isLoading}
        />
      </Block>
    </Block>
  );
};

export const LoginPage = React.memo(_LoginPage);
