import { VehicleSpec } from '../../../models/vehicle/vehicleSpec';
import { VehicleModel } from '../../../models/vehicle/vehicleModel';

import { VehicleMake } from '../../../models/vehicle/vehicleMake';

const vehicleMakesMock: VehicleMake[] = [
  { id: 'cc195ed4-a6f1-4dc9-bfa5-7ceddbe73b6a', name: 'BMW' },
  { id: '3', name: 'mercedes' },
  { id: '4', name: 'aston martin' },
];

const vehicleModelsMock: VehicleModel[] = [
  { id: 'cc195ed4-a6f1-4dc9-bfa5-7ceddbe73b6a', name: '320d' },
  { id: 'cc195ed4-a6f1-4dc9-bfa5-7ceddbe73b6b', name: '318' },
  { id: '3', name: 'C220' },
  { id: '4', name: 'class a' },
  { id: '7', name: 'dbx' },
  { id: '8', name: 'valkyrie' },
];

export const vehicleSpecsMock: VehicleSpec[] = [
  {
    make: vehicleMakesMock[0],
    models: vehicleModelsMock.slice(0, 2),
  },
  {
    make: vehicleMakesMock[1],
    models: vehicleModelsMock.slice(2, 4),
  },
  {
    make: vehicleMakesMock[2],
    models: vehicleModelsMock.slice(4, 6),
  },
];
