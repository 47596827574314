import { LocalStorageProvider } from '../../../../domain/gateways/localStorageProvider';
import { AuthenticatedUser } from '../../../../domain/models/authenticatedUser';

export class WebLocalStorageProvider implements LocalStorageProvider {
  async setAuthenticatedUser(credentials: AuthenticatedUser): Promise<void> {
    localStorage.setItem('authenticatedUser', JSON.stringify(credentials));
  }

  async getAuthenticatedUser(): Promise<AuthenticatedUser | null> {
    const user = localStorage.getItem('authenticatedUser');
    if (!user) return null;
    return JSON.parse(user);
  }

  async removeAuthenticatedUser(): Promise<void> {
    localStorage.removeItem('authenticatedUser');
  }
}
