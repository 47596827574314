import { AppThunk } from '../../../store/reduxStore';
import {
  setLoadingVehicleSpecs,
  setVehicleSpecs,
} from '../../reducers/vehicleSpecs.reducer';

export const retrieveVehicleSpecs =
  (): AppThunk => async (dispatch, getState, dependencies) => {
    if (getState().vehicleSpecs.vehicleSpecs !== null) {
      return;
    }

    const cachedVehicleSpecs = await dependencies.cacheProvider.getVehicleSpecs();

    if (cachedVehicleSpecs) {
      dispatch(setVehicleSpecs(cachedVehicleSpecs));
    } else {
      dispatch(setLoadingVehicleSpecs());
    }

    try {
      const vehicleSpecs = await dependencies.vehicleSpecGateway.retrieveAll();

      await dependencies.cacheProvider.setVehicleSpecs(vehicleSpecs);

      dispatch(setVehicleSpecs(vehicleSpecs));
    } catch (error) {
      console.error(error);
    }
  };
