import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from '../theme/useTranslation';
import { useTheme } from '../theme/useTheme';
import { CreateAlertInput } from '../../ports/primary/ui/types/CreateAlertInput';
import { Block, IconButton, Input, MultiSelect, Select, Slider, Text } from '../atoms';
import { LabeledBlock } from '../molecules';
import { CreateAlertError } from '../../ports/primary/ui/hooks/create-alert-screen/useCreateAlertScreen.state';
import { VehicleSpec } from '../../domain/models/vehicle/vehicleSpec';
import { VehicleMake } from '../../domain/models/vehicle/vehicleMake';
import { City } from '../../domain/models/city';

interface Props {
  alert: CreateAlertInput;
  error: CreateAlertError | null;
  cities: City[];
  vehicleSpecs: VehicleSpec[];
  onChangeAlert: (alert: Partial<CreateAlertInput>) => void;
  onDeleteAlert: () => void;
}

const _CreateAlertCard: FC<Props> = ({
  alert,
  error,
  cities,
  vehicleSpecs,
  onChangeAlert,
  onDeleteAlert,
}) => {
  const { t } = useTranslation();
  const { sizes, icons, colors } = useTheme();

  const getVehicleModels = useCallback(
    (makeId: string | undefined) => {
      if (!makeId) return [];
      const vehicleSpec = vehicleSpecs.find(_ => _.make.id === makeId);
      return vehicleSpec?.models ?? [];
    },
    [vehicleSpecs],
  );

  const cityOptions = useMemo(
    () => cities.map(_ => ({ label: _.name, value: _ })),
    [cities],
  );

  const vehicleMakeOptions = useMemo(
    () => vehicleSpecs.map(_ => ({ label: _.make.name, value: _.make })),
    [vehicleSpecs],
  );

  const vehicleModelOptions = useMemo(() => {
    const models = getVehicleModels(alert.vehicleMake?.id);

    return models.map(_ => ({ label: _.name, value: _ }));
  }, [alert.vehicleMake?.id, getVehicleModels]);

  const onVehicleMakeChange = useCallback(
    (vehicleMake: VehicleMake) => {
      onChangeAlert({ vehicleMake, vehicleModels: [] });
    },
    [onChangeAlert],
  );

  return (
    <Block card padding={sizes.sm} gap={sizes.s} position="relative">
      <IconButton
        position="absolute"
        right={0}
        top={0}
        size={sizes.sm}
        padding={sizes.xs}
        minHeight={0}
        minWidth={0}
        icon={icons.trash}
        color={colors.danger}
        onPress={onDeleteAlert}
      />
      <LabeledBlock label={t('myAlerts.city')}>
        <Select
          label={alert.city.name}
          placeholder={t('myAlerts.city')}
          options={cityOptions}
          onSelectValue={city => onChangeAlert({ city })}
          danger={error?.city}
        />
        <Slider
          minimumValue={0}
          maximumValue={100}
          value={alert.cityRadius}
          onValueChange={cityRadius => onChangeAlert({ cityRadius })}
        />
        <Text>{t(`myAlerts.cityRadius`, { radius: alert.cityRadius })}</Text>
      </LabeledBlock>

      <LabeledBlock label={t('myAlerts.vehicleType')}>
        <Block row justify="space-between" gap={sizes.xs}>
          <Select
            label={alert.vehicleMake.name}
            placeholder={t('myAlerts.vehicleMake')}
            options={vehicleMakeOptions}
            onSelectValue={onVehicleMakeChange}
            danger={error?.vehicleMake}
          />
          <MultiSelect
            placeholder={t('myAlerts.vehicleModel')}
            disabled={!alert.vehicleMake.id}
            options={vehicleModelOptions}
            selectedValues={alert.vehicleModels}
            onSelectValue={vehicleModels => onChangeAlert({ vehicleModels })}
            danger={error?.vehicleModel}
          />
        </Block>
      </LabeledBlock>

      <LabeledBlock label={t('myAlerts.budget')}>
        <Block row justify="space-between" gap={sizes.xs}>
          <Input
            fullWidth
            number
            danger={error?.minBudget}
            placeholder={t('common.min')}
            value={alert.minBudget?.toString()}
            onChangeText={minBudget => {
              const parsedBudget = parseInt(minBudget, 10);
              onChangeAlert({
                minBudget: Number.isNaN(parsedBudget) ? null : parsedBudget,
              });
            }}
          />
          <Input
            fullWidth
            number
            danger={error?.maxBudget}
            placeholder={t('common.max')}
            value={alert.maxBudget?.toString()}
            onChangeText={maxBudget =>
              onChangeAlert({ maxBudget: parseInt(maxBudget, 10) || null })
            }
          />
        </Block>
      </LabeledBlock>
    </Block>
  );
};

export const CreateAlertCard = React.memo(_CreateAlertCard);
