import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useDispatch } from 'react-redux';
import { CreateAlertInput } from '../../types/CreateAlertInput';
import { numberRegex } from '../../../../../design-system/constants/regex';
import { AppDispatch } from '../../../../../store/reduxStore';
import { saveAlerts as saveAlertsCommand } from '../../../../../domain/use-cases/alerts/saveAlerts';
import { AlertAdapter } from '../../../adapters/alert.adapter';

export interface CreateAlertError {
  id: string;
  city: boolean;
  vehicleMake: boolean;
  vehicleModel: boolean;
  minBudget: boolean;
  maxBudget: boolean;
}

const MAX_CREATABLE_ALERTS = 3;

export const useCreateAlertScreenState = (createdAlerts: CreateAlertInput[] | null) => {
  const dispatch = useDispatch<AppDispatch>();

  const [alerts, setAlerts] = useState<CreateAlertInput[]>(createdAlerts ?? []);
  const [errors, setErrors] = useState<CreateAlertError[]>([]);

  const canAddAlert = useMemo(() => alerts.length < MAX_CREATABLE_ALERTS, [alerts]);

  const executeAfterValidation = useCallback(
    (callback: () => void) => {
      const alertsError = getAlertsError(alerts);
      if (alertsError.length > 0) return setErrors(alertsError);

      setErrors([]);
      callback();
    },
    [alerts],
  );

  const onChangeAlert = useCallback(
    (alertId: string, newAlert: Partial<CreateAlertInput>) => {
      const newAlerts = alerts.map(_ => (_.id === alertId ? { ..._, ...newAlert } : _));

      setAlerts(newAlerts);
    },
    [alerts, setAlerts],
  );

  const addAlert = useCallback(() => {
    executeAfterValidation(() => {
      setAlerts(prevAlerts => [...prevAlerts, createNewAlert()]);
    });
  }, [executeAfterValidation]);

  const deleteAlert = useCallback(
    (alertId: string) => {
      setAlerts(prevAlerts => prevAlerts.filter(_ => _.id !== alertId));
    },
    [setAlerts],
  );

  const saveAlerts = useCallback(() => {
    executeAfterValidation(async () =>
      dispatch(saveAlertsCommand(AlertAdapter.toDomain(alerts))),
    );
  }, [alerts, dispatch, executeAfterValidation]);

  useEffect(() => {
    if (!createdAlerts?.length) return;
    setAlerts(createdAlerts);
  }, [createdAlerts]);

  return {
    alerts,
    errors,
    onChangeAlert,
    canAddAlert,
    addAlert,
    deleteAlert,
    saveAlerts,
  };
};

const getAlertsError = (alerts: CreateAlertInput[]): CreateAlertError[] => {
  return alerts.reduce<CreateAlertError[]>((acc, _) => {
    const error = getAlertErrors(_);
    return error ? [...acc, error] : acc;
  }, []);
};

const getAlertErrors = (alert: CreateAlertInput): CreateAlertError | null => {
  const cityError = !alert.city.id;
  const vehicleMakeError = !alert.vehicleMake.id;
  const vehicleModelError = !alert.vehicleModels.length;
  const minBudgetError =
    alert.minBudget === null || !numberRegex.test(alert.minBudget.toString());
  const maxBudgetError =
    !alert.maxBudget ||
    !numberRegex.test(alert.maxBudget.toString()) ||
    alert.maxBudget < (alert.minBudget || 0);

  if (
    cityError ||
    vehicleMakeError ||
    vehicleModelError ||
    minBudgetError ||
    maxBudgetError
  ) {
    return {
      id: alert.id,
      city: cityError,
      vehicleMake: vehicleMakeError,
      vehicleModel: vehicleModelError,
      minBudget: minBudgetError,
      maxBudget: maxBudgetError,
    };
  }

  return null;
};

const createNewAlert = (): CreateAlertInput => ({
  id: uuid(),
  city: { id: '', name: '' },
  cityRadius: 10,
  vehicleMake: { id: '', name: '' },
  vehicleModels: [],
  minBudget: null,
  maxBudget: null,
});
