import { VehicleSpec } from '../../../../../domain/models/vehicle/vehicleSpec';
import { VehicleSpecResponse } from '../types/graphqlVehicleSpecGateway.types';

export class VehicleSpecAdapter {
  static fromVehicleSpecResponse(response: VehicleSpecResponse): VehicleSpec {
    return {
      make: {
        id: response.id,
        name: response.makeName,
      },
      models: response.models.map(model => ({
        id: model.id,
        name: model.name,
      })),
    };
  }
}
