import { AppThunk } from '../../../store/reduxStore';
import { OAuthProviderName } from '../../gateways/providers/oAuthProvider';
import {
  setAuthentication,
  setAuthenticationError,
  setLoadingAuthentication,
} from '../../reducers/authenticationPerformer.reducer';

export const oAuthUser =
  (token: string, provider: OAuthProviderName): AppThunk =>
  async (dispatch, _getState, dependencies) => {
    dispatch(setLoadingAuthentication());

    try {
      const externalUser = await dependencies.oAuthProvider.getUser(token, provider);

      const user = await dependencies.authenticationGateway.authenticateExternalUser(
        externalUser,
        provider,
      );

      await dependencies.localStorageProvider.setAuthenticatedUser(user);

      dispatch(setAuthentication(user));
    } catch (e) {
      console.error('oAuth error:', e);
      dispatch(setAuthenticationError({ loginError: 'Invalid credentials' }));
    }
  };
