import { NODE_ENV } from '@env';
import { useEffect } from 'react';

export const useEnv = () => {
  const isDev = NODE_ENV === 'development';
  const isProd = NODE_ENV === 'production';
  const isStaging = NODE_ENV === 'staging';

  useEffect(() => {
    console.info('NODE_ENV:', NODE_ENV);
  }, []);

  return {
    isDev,
    isProd,
    isStaging,
  };
};
