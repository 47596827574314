import { LocalStorageProvider } from '../../../../domain/gateways/localStorageProvider';
import { AuthenticatedUser } from '../../../../domain/models/authenticatedUser';

export class DeterministicLocalStorageProvider implements LocalStorageProvider {
  private _storage: { authenticatedUser?: AuthenticatedUser } = {};

  async setAuthenticatedUser(credentials: AuthenticatedUser): Promise<void> {
    this._storage.authenticatedUser = credentials;
  }

  getAuthenticatedUser(): Promise<AuthenticatedUser | null> {
    const authenticatedUser = this._storage.authenticatedUser ?? null;

    return Promise.resolve(authenticatedUser);
  }

  async removeAuthenticatedUser(): Promise<void> {
    this._storage.authenticatedUser = undefined;
  }

  get storage() {
    return this._storage;
  }
}
