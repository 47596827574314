import React, { FC } from 'react';
import { Block } from './Block';
import { Text } from './Text';
import { useTheme } from '../theme/useTheme';

interface Props {
  text: string;
}

const _Chip: FC<Props> = ({ text }) => {
  const { sizes, gradients } = useTheme();

  return (
    <Block
      justify="center"
      radius={sizes.m}
      paddingHorizontal={sizes.s}
      gradient={gradients.secondary}
    >
      <Text white bold transform="uppercase">
        {text}
      </Text>
    </Block>
  );
};

export const Chip = React.memo(_Chip);
