import React, { FC } from 'react';
import { ImageSourcePropType } from 'react-native';
import { useTheme } from '../../theme/useTheme';
import { Button } from './Button';
import { Block } from '../Block';
import { Image } from '../Image';
import { Text } from '../Text';

interface Props {
  icon: ImageSourcePropType;
  screenName: string;
  isActive: boolean;
  onPress: () => void;
}

const _NavButton: FC<Props> = ({ icon, isActive, screenName, onPress }) => {
  const { colors, gradients, sizes } = useTheme();

  return (
    <Button row justify="flex-start" marginBottom={sizes.s} onPress={onPress}>
      <Block
        radius={6}
        align="center"
        justify="center"
        width={sizes.md}
        height={sizes.md}
        marginRight={sizes.s}
        gradient={gradients[isActive ? 'primary' : 'white']}
      >
        <Image
          radius={0}
          width={sizes.text}
          height={sizes.text}
          source={icon}
          color={colors[isActive ? 'white' : 'black']}
        />
      </Block>
      <Text p semibold={isActive} color={colors.text}>
        {screenName}
      </Text>
    </Button>
  );
};

export const NavButton = React.memo(_NavButton);
