import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../../../../store/appState';
import { AnnouncementVM } from './announcementVM';
import { AppSelector } from '../../../../store/reduxStore';
import { AnnouncementAdapter } from '../../adapters/announcement.adapter';

const selector = (state: AppState) => state;

export const selectAnnouncements: AppSelector<AnnouncementVM> = createSelector(
  [selector],
  (state: AppState) => ({
    announcements: state.announcements.announcements?.map(AnnouncementAdapter.toUi) || [],
    isLoading: state.announcements.isLoading,
    error: state.announcements.error,
  }),
);
