import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../../../../store/appState';
import { AppSelector } from '../../../../store/reduxStore';
import { MyAlertsVM } from './myAlertsVM';

const selector = (state: AppState) => state;

export const selectMyAlerts: AppSelector<MyAlertsVM | null> = createSelector(
  [selector],
  (state: AppState) => {
    return {
      isLoading:
        state.alerts.isLoading || state.vehicleSpecs.isLoading || state.cities.isLoading,
      alerts: state.alerts.createdAlerts ?? [],
      isLoadingSave: state.alerts.saving.isLoading,
      vehicleSpecs: state.vehicleSpecs.vehicleSpecs ?? [],
      cities: state.cities.cities ?? [],
    };
  },
);
