import React, { FC } from 'react';
import { useNavigation } from '@react-navigation/native';
import { Block, Image, LabeledCheckbox, PrimaryButton, Text } from '../atoms';
import { useTheme } from '../theme/useTheme';
import { ImageHeader } from '../organisms';
import { ProfileVM } from '../../ports/primary/view-models/profile/profileVM';
import { useTranslation } from '../theme/useTranslation';

interface Props {
  profileVM: ProfileVM | null;
  isNotificationEnabled: boolean;
  onChangeEnableNotifications: (value: boolean) => void;
  onLogout: () => void;
}

const _ProfilePage: FC<Props> = ({
  profileVM,
  onLogout,
  isNotificationEnabled,
  onChangeEnableNotifications,
}) => {
  const { sizes, assets } = useTheme();
  const { navigate } = useNavigation();
  const { t } = useTranslation();

  if (!profileVM) {
    navigate('Login');
    return;
  }

  return (
    <Block safe paddingVertical={sizes.md} paddingHorizontal={sizes.s} flex={1}>
      <Block scroll showsVerticalScrollIndicator={false}>
        <ImageHeader withBackButton>
          <Block align="center" gap={sizes.s}>
            <Image
              width={64}
              height={64}
              source={
                profileVM.picture ? { uri: profileVM.picture } : assets.defaultAvatar
              }
            />
            <Text h5 white>
              {profileVM.name}
            </Text>
            <Text p white>
              {profileVM.email}
            </Text>
          </Block>
        </ImageHeader>
        <Block gap={sizes.m} marginTop={sizes.sm} flex={1}>
          <LabeledCheckbox
            label={t('profile.enableNotifications')}
            checked={isNotificationEnabled}
            onChange={onChangeEnableNotifications}
          />
          <PrimaryButton
            fullWidth
            outlined
            text={t('common.signout')}
            onPress={onLogout}
          />
        </Block>
      </Block>
    </Block>
  );
};

export const ProfilePage = React.memo(_ProfilePage);
