import { AuthenticationGateway } from '../../../../domain/gateways/authenticationGateway';
import { AuthenticatedUser } from '../../../../domain/models/authenticatedUser';
import { ExternalUser } from '../../../../domain/models/externalUser';
import { RegisterUserCommand } from '../../../../domain/models/commands/registerUserCommand';
import { inMemoryLoading } from './inMemoryLoading';
import { LoginUserCommand } from '../../../../domain/models/commands/loginUserCommand';

export class InMemoryAuthenticationGateway implements AuthenticationGateway {
  private _registeredUsers: RegisterUserCommand[] = [];

  private _nextAuthenticatedUser: AuthenticatedUser | null = null;

  async loginUser(credentials: LoginUserCommand): Promise<AuthenticatedUser> {
    await inMemoryLoading();

    const user = this._registeredUsers?.find(
      _ => _.email === credentials.email && _.password === credentials.password,
    );

    if (!user) {
      throw new Error('Invalid credentials');
    }

    return Promise.resolve({
      token: user.email,
      user: {
        id: user.email,
        email: user.email,
        name: user.name,
        isEmailVerified: true,
        isNotificationsEnabled: true,
      },
    });
  }

  async registerUser(user: RegisterUserCommand): Promise<void> {
    await inMemoryLoading();

    const isAlreadyRegistered = this._registeredUsers?.some(_ => _.email === user.email);

    if (isAlreadyRegistered) {
      throw new Error('User already registered');
    }

    this._registeredUsers.push(user);
  }

  async authenticateExternalUser(externalUser: ExternalUser): Promise<AuthenticatedUser> {
    await inMemoryLoading();

    return Promise.resolve({
      token: externalUser.email,
      user: {
        id: externalUser.email,
        email: externalUser.email,
        name: externalUser.name,
        isEmailVerified: externalUser.isEmailVerified,
        picture: externalUser.picture,
        isNotificationsEnabled: true,
      },
    });
  }

  get registeredUsers() {
    return this._registeredUsers;
  }

  set registeredUsers(_registeredUsers: RegisterUserCommand[]) {
    this._registeredUsers = _registeredUsers;
  }

  async retrieveLoggedInUser(): Promise<AuthenticatedUser | null> {
    await inMemoryLoading();

    return this._nextAuthenticatedUser;
  }

  set nextAuthenticatedUser(_nextAuthenticatedUser: AuthenticatedUser | null) {
    this._nextAuthenticatedUser = _nextAuthenticatedUser;
  }
}
