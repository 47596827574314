import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppState } from '../../store/appState';
import { AuthenticatedUser } from '../models/authenticatedUser';

const initialState: AppState['authentication'] = {
  authenticatedUser: null,
  isLoading: false,
  loginError: null,
  registerError: null,
};

const _setLoadingAuthentication = (state: AppState['authentication']) => {
  state.isLoading = true;
  state.loginError = null;
  state.registerError = null;
};

const _setAuthentication = (
  state: AppState['authentication'],
  action: PayloadAction<AuthenticatedUser | null>,
) => {
  state.authenticatedUser = action.payload;
  state.isLoading = false;
  state.loginError = null;
  state.registerError = null;
};

const _setAuthenticationError = (
  state: AppState['authentication'],
  action: PayloadAction<{ registerError?: string; loginError?: string }>,
) => {
  state.authenticatedUser = null;
  state.loginError = action.payload.loginError || null;
  state.registerError = action.payload.registerError || null;
  state.isLoading = false;
};

const _updateCurrentUser = (
  state: AppState['authentication'],
  action: PayloadAction<Partial<NonNullable<AuthenticatedUser['user']>>>,
) => {
  if (state.authenticatedUser?.user) {
    state.authenticatedUser.user = { ...state.authenticatedUser.user, ...action.payload };
  }
};

const authenticationPerformerSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setLoadingAuthentication: _setLoadingAuthentication,
    setAuthentication: _setAuthentication,
    setAuthenticationError: _setAuthenticationError,
    updateCurrentUser: _updateCurrentUser,
  },
});

export const { reducer: authenticationPerformerReducer } = authenticationPerformerSlice;
export const {
  setLoadingAuthentication,
  setAuthentication,
  setAuthenticationError,
  updateCurrentUser,
} = authenticationPerformerSlice.actions;
