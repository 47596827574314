import { useState, useEffect, useRef } from 'react';
import { Platform, Alert } from 'react-native';
import * as Notifications from 'expo-notifications';
import Constants from 'expo-constants';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../../store/reduxStore';
import { saveNotificationToken } from '../../../../../domain/use-cases/notifications/saveNotificationToken';
import { selectProfile } from '../../../view-models/profile/profileVM.selectors';
import { NotificationProvider } from '../../../../../domain/models/notificationProvider';
import { useClickOnNotification } from './useClickOnNotification';
import { NotificationData } from '../../types/NotificationData';

export interface PushNotificationState {
  expoPushToken?: Notifications.ExpoPushToken;
  notification?: Notifications.Notification;
}

export const usePushNotifications = (): PushNotificationState => {
  const dispatch = useDispatch<AppDispatch>();

  const profileVM = useSelector(selectProfile);
  const { handleNotificationClick } = useClickOnNotification();

  Notifications.setNotificationHandler({
    handleNotification: async () => ({
      shouldPlaySound: false,
      shouldShowAlert: true,
      shouldSetBadge: false,
    }),
  });

  const [expoPushToken, setExpoPushToken] = useState<
    Notifications.ExpoPushToken | undefined
  >();

  const [notification, setNotification] = useState<
    Notifications.Notification | undefined
  >();

  const notificationListener = useRef<Notifications.Subscription>();
  const responseListener = useRef<Notifications.Subscription>();

  useEffect(() => {
    registerForPushNotificationsAsync()
      .then(token => setExpoPushToken(token))
      .catch(error => console.error(error));

    // This listener is fired whenever a notification is received while the app is foregrounded
    notificationListener.current = Notifications.addNotificationReceivedListener(
      _notification => setNotification(_notification),
    );

    // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
    responseListener.current = Notifications.addNotificationResponseReceivedListener(
      response => {
        const {
          notification: {
            request: {
              content: { data },
            },
          },
        } = response;

        if (data) {
          handleNotificationClick(data as NotificationData);
        }
      },
    );

    return () => {
      Notifications.removeNotificationSubscription(notificationListener.current!);
      Notifications.removeNotificationSubscription(responseListener.current!);
    };
  }, [handleNotificationClick]);

  useEffect(() => {
    if (expoPushToken && profileVM) {
      dispatch(saveNotificationToken(expoPushToken.data, NotificationProvider.EXPO_PUSH));
    }
  }, [dispatch, expoPushToken, profileVM]);

  return { expoPushToken, notification };
};

const registerForPushNotificationsAsync = async () => {
  const { status: existingStatus } = await Notifications.getPermissionsAsync();
  let finalStatus = existingStatus;

  if (existingStatus !== 'granted') {
    const { status } = await Notifications.requestPermissionsAsync();
    finalStatus = status;
  }
  if (finalStatus !== 'granted') {
    Alert.alert('Failed to get push token for push notification');
    return;
  }

  const token = await Notifications.getExpoPushTokenAsync({
    projectId: Constants.expoConfig?.extra?.eas.projectId,
  });

  if (Platform.OS === 'android') {
    await Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    });
  }

  return token;
};
