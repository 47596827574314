import { Alert } from '../../../models/alert';
import { vehicleSpecsMock } from './vehicleSpecsMock';

export const alertsMock: Alert[] = [
  {
    id: 'cc195ed4-a6f1-4dc9-bfa5-7ceddbe73b6a',
    city: { id: 'cc195ed4-a6f1-4dc9-bfa5-7ceddbe73b6a', name: 'casablanca' },
    cityRadius: 10,
    vehicleMake: vehicleSpecsMock[0].make,
    vehicleModels: vehicleSpecsMock[0].models.slice(0, 1),
    minBudget: 0,
    maxBudget: 200000,
  },
];
