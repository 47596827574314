import { CacheProvider } from '../../../../domain/gateways/providers/cacheProvider';
import { City } from '../../../../domain/models/city';
import { VehicleSpec } from '../../../../domain/models/vehicle/vehicleSpec';

export class InMemoryCacheProvider implements CacheProvider {
  private _vehicleSpecs: VehicleSpec[] | undefined = undefined;

  private _cities: City[] | undefined = undefined;

  async setVehicleSpecs(vehicleSpecs: VehicleSpec[]): Promise<void> {
    this._vehicleSpecs = vehicleSpecs;
  }

  async getVehicleSpecs(): Promise<VehicleSpec[] | undefined> {
    return this._vehicleSpecs;
  }

  async setCities(cities: City[]): Promise<void> {
    this._cities = cities;
  }

  async getCities(): Promise<City[] | undefined> {
    return this._cities;
  }

  get cachedItems() {
    return { vehicleSpecs: this._vehicleSpecs, cities: this._cities };
  }
}
