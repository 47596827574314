import React, { useMemo } from 'react';

import { ITheme, IThemeProvider } from './theme.types';
import { lightTheme } from './lightTheme';

export const ThemeContext = React.createContext({
  theme: lightTheme,
  setTheme: () => {},
});

export const ThemeProvider = ({
  children,
  theme = lightTheme,
  setTheme = () => {},
}: IThemeProvider) => {
  const value = useMemo(() => ({ theme, setTheme }), [theme, setTheme]);
  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
};

export const useTheme = (): ITheme => {
  const { theme } = React.useContext(ThemeContext);
  return theme;
};
