import React from 'react';
import { ActivityIndicator } from 'react-native';
import { useTheme } from '../../theme/useTheme';

const _SpinnerLoader = () => {
  const { colors } = useTheme();

  return <ActivityIndicator animating size="large" color={colors.primary} />;
};

export const SpinnerLoader = React.memo(_SpinnerLoader);
