import { CityGateway } from '../../../../../domain/gateways/cityGateway';
import { City } from '../../../../../domain/models/city';
import { CityResponse } from '../types/graphqlCityGateway.types';
import { ApolloClient } from './apolloClient';
import { GET_CITIES } from './queries/getCities.query';

export class GraphqlCityGateway implements CityGateway {
  async retrieveAll(): Promise<City[]> {
    try {
      const client = await ApolloClient.getInstance();
      const { data } = await client.query<{
        cities: CityResponse[];
      }>({ query: GET_CITIES });

      return data?.cities ?? [];
    } catch (error) {
      throw new Error(`Failed to retrieve vehicle specs: ${JSON.stringify(error)}`);
    }
  }
}
