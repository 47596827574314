import { NotificationGateway } from '../../../../domain/gateways/notificationGateway';

export class InMemoryNotificationGateway implements NotificationGateway {
  private _token: string | null = null;

  private _itShouldFail = false;

  async saveToken(token: string): Promise<boolean> {
    if (this._itShouldFail) {
      return false;
    }

    this._token = token;
    return true;
  }

  async updateEnablingStatus(): Promise<void> {
    if (this._itShouldFail) {
      throw new Error('Error updating status');
    }
  }

  set itShouldFail(shouldFail: boolean) {
    this._itShouldFail = shouldFail;
  }

  get token() {
    return this._token;
  }
}
