import React, { FC, useCallback } from 'react';
import { Linking, TextStyle } from 'react-native';
import { useTheme } from '../theme/useTheme';
import { Block } from './Block';
import { Checkbox } from './Checkbox';
import { Text } from './Text';

interface Props {
  checked: boolean;
  onChange: (value: boolean) => void;
  label: string;
  transformText?: TextStyle['textTransform'];
  link?: { text: string; url: string };
}

const _LabeledCheckbox: FC<Props> = ({
  checked,
  onChange,
  transformText,
  label,
  link,
}) => {
  const { sizes } = useTheme();
  const onCheck = useCallback(() => onChange(!checked), [checked, onChange]);

  return (
    <Block row gap={sizes.sm} align="center" onPress={onCheck}>
      <Checkbox checked={checked} onCheck={onCheck} />
      <Text transform={transformText} p>
        {label}
        {link && (
          <Text semibold onPress={() => Linking.openURL(link.url)}>
            {link.text}
          </Text>
        )}
      </Text>
    </Block>
  );
};

export const LabeledCheckbox = React.memo(_LabeledCheckbox);
