import { AnnouncementGateway } from '../../../../domain/gateways/announcementGateway';
import { Announcement } from '../../../../domain/models/announcement';
import { inMemoryLoading } from './inMemoryLoading';

export class InMemoryAnnouncementGateway implements AnnouncementGateway {
  private _nextAnnouncements: Announcement[] | null = null;

  async retrieveAnnouncements(): Promise<Announcement[]> {
    await inMemoryLoading();
    return this._nextAnnouncements || [];
  }

  feedWith(...announcements: Announcement[]) {
    this._nextAnnouncements = announcements;
  }
}
