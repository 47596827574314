import 'react-native-gesture-handler';
import 'react-native-get-random-values';
import React, { useLayoutEffect } from 'react';
import { Provider } from 'react-redux';
import { AppNavigation } from './src/ports/primary/ui/navigation/AppNavigation';
import { DataProvider } from './src/design-system/theme/useData';
import { injectDependencies } from './src/injectDependencies';
import { ReduxStore, initReduxStore } from './src/store/reduxStore';
import { retrieveLoggedInUser } from './src/domain/use-cases/authentication/loggedInUserRetrieval';
import { retrieveVehicleSpecs } from './src/domain/use-cases/vehicle-spec-retrieval/retrieveVehicleSpecs';
import { retrieveCities } from './src/domain/use-cases/cities-retrieval/retrieveCities';

const App = () => {
  const [store, setStore] = React.useState<ReduxStore | null>(null);

  useLayoutEffect(() => {
    void (async () => {
      const dependencies = await injectDependencies('http');
      const reduxStore: ReduxStore = initReduxStore(dependencies);
      reduxStore.dispatch(retrieveLoggedInUser());
      reduxStore.dispatch(retrieveVehicleSpecs());
      reduxStore.dispatch(retrieveCities());
      setStore(reduxStore);
    })();
  }, []);

  return store ? (
    <Provider store={store}>
      <DataProvider>
        <AppNavigation />
      </DataProvider>
    </Provider>
  ) : null;
};

export default App;
