import { AuthenticationGateway } from '../../../../../domain/gateways/authenticationGateway';
import {
  AuthenticatedUser,
  UNAUTHORIZED_USER_ERROR,
} from '../../../../../domain/models/authenticatedUser';
import { LOGIN_USER } from './mutations/loginUser.mutation';
import { REGISTER_USER } from './mutations/registerUser.mutation';
import { ApolloClient } from './apolloClient';
import { GET_ME } from './queries/getMe.query';
import { ExternalUser } from '../../../../../domain/models/externalUser';
import { RegisterUserCommand } from '../../../../../domain/models/commands/registerUserCommand';
import { LoginUserCommand } from '../../../../../domain/models/commands/loginUserCommand';
import { LoginUserResponse } from '../types/graphqlAuthenticationGateway.types';
import { AuthenticatedUserAdapter } from '../adapters/authenticatedUserAdapter';
import { O_AUTH_USER } from './mutations/oAuthUser.mutation';
import { OAuthProviderName } from '../../../../../domain/gateways/providers/oAuthProvider';

export class GraphqlAuthenticationGateway implements AuthenticationGateway {
  async registerUser(credentials: RegisterUserCommand): Promise<void> {
    const client = await ApolloClient.getInstance();
    const { data } = await client.mutate({
      mutation: REGISTER_USER,
      variables: { user: credentials },
    });

    if (!data?.register) {
      throw new Error('Error registering user');
    }
  }

  async loginUser(credentials: LoginUserCommand): Promise<AuthenticatedUser> {
    const client = await ApolloClient.getInstance();
    const { data } = await client.mutate<{ logIn: LoginUserResponse }>({
      mutation: LOGIN_USER,
      variables: { user: credentials },
    });

    if (!data?.logIn) {
      throw new Error('Invalid credentials');
    }

    return AuthenticatedUserAdapter.fromGraphqlResponse(data.logIn);
  }

  async retrieveLoggedInUser(): Promise<AuthenticatedUser | null> {
    try {
      const client = await ApolloClient.getInstance();
      const { data } = await client.query<{ me: LoginUserResponse | null }>({
        query: GET_ME,
      });

      return data.me ? AuthenticatedUserAdapter.fromGraphqlResponse(data.me) : null;
    } catch (error: any) {
      if (error?.message === 'Unauthorized') {
        throw new Error(UNAUTHORIZED_USER_ERROR);
      }
      console.error(error);
      return null;
    }
  }

  async authenticateExternalUser(
    externalUser: ExternalUser,
    _oAuthProvider: OAuthProviderName,
  ): Promise<AuthenticatedUser> {
    const client = await ApolloClient.getInstance();
    const { data } = await client.mutate<{ oAuthUser: LoginUserResponse }>({
      mutation: O_AUTH_USER,
      variables: { user: { ...externalUser, oAuthProvider: 'GOOGLE' } },
    });

    if (!data?.oAuthUser) {
      throw new Error('Error Oauth user');
    }

    return AuthenticatedUserAdapter.fromGraphqlResponse(data.oAuthUser);
  }
}
