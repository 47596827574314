import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppState } from '../../store/appState';
import { City } from '../models/city';

const initialState: AppState['cities'] = {
  cities: null,
  isLoading: false,
  error: null,
};

const _setCities = (state: AppState['cities'], action: PayloadAction<City[]>) => {
  state.isLoading = false;
  state.cities = action.payload;
};

const _setLoadingCities = (state: AppState['cities']) => {
  state.isLoading = true;
};

const citiesSlice = createSlice({
  name: 'cities',
  initialState,
  reducers: {
    setLoadingCities: _setLoadingCities,
    setCities: _setCities,
  },
});

export const { setCities, setLoadingCities } = citiesSlice.actions;

export const { reducer: citiesReducer } = citiesSlice;
