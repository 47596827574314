import { useCallback, useEffect } from 'react';
import * as WebBrowser from 'expo-web-browser';
import * as Google from 'expo-auth-session/providers/google';
import { useDispatch } from 'react-redux';
import {
  GOOGLE_ANDROID_CLIENT_ID,
  GOOGLE_IOS_CLIENT_ID,
  GOOGLE_WEB_CLIENT_ID,
} from '@env';
import { AppDispatch } from '../../../../store/reduxStore';
import { oAuthUser } from '../../../../domain/use-cases/authentication/oAuthUser';
import { OAuthProviderName } from '../../../../domain/gateways/providers/oAuthProvider';

const config = {
  androidClientId: GOOGLE_ANDROID_CLIENT_ID,
  iosClientId: GOOGLE_IOS_CLIENT_ID,
  webClientId: GOOGLE_WEB_CLIENT_ID,
};

WebBrowser.maybeCompleteAuthSession();

export const useGoogleAuth = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [_request, response, handleGoogleAuth] = Google.useAuthRequest(config);

  const signInWithGoogle = useCallback(async () => {
    if (!response) {
      return;
    }
    if (response.type === 'success' && response.authentication?.accessToken) {
      dispatch(oAuthUser(response.authentication.accessToken, OAuthProviderName.GOOGLE));
    } else {
      console.error('error during google sign in prompt');
    }
  }, [dispatch, response]);

  useEffect(() => {
    void signInWithGoogle();
  }, [response, signInWithGoogle]);

  return { handleGoogleAuth };
};
