import React, { FC } from 'react';
import { ColorValue, ImageSourcePropType, ViewStyle } from 'react-native';
import { useTheme } from '../../theme/useTheme';
import { Button } from './Button';
import { Block } from '../Block';
import { Image } from '../Image';
import { Text } from '../Text';

interface Props {
  text: string;
  themeIcon?: ImageSourcePropType;
  icon?: React.ReactNode;
  endIcon?: ImageSourcePropType | React.ReactNode;
  dimension?: number;
  textColor?: ColorValue;
  isActive?: boolean;
  align?: ViewStyle['alignItems'];
  justify?: ViewStyle['justifyContent'];
  onPress: () => void;
}

const _LabeledIconButton: FC<Props> = ({
  isActive,
  themeIcon,
  endIcon,
  icon,
  text,
  textColor,
  dimension,
  align = 'center',
  justify = 'center',
  onPress,
}) => {
  const { colors, gradients, sizes, fonts } = useTheme();

  return (
    <Button row align={align} justify={justify} onPress={onPress}>
      {icon}
      {themeIcon && (
        <Block
          radius={6}
          align="center"
          justify="center"
          width={dimension || sizes.md}
          height={dimension || sizes.md}
          gradient={gradients[isActive ? 'primary' : 'secondary']}
        >
          <Image source={themeIcon} color={colors.white} radius={0} />
        </Block>
      )}
      <Text
        p
        color={textColor}
        marginLeft={sizes.s}
        font={fonts[isActive ? 'medium' : 'normal']}
      >
        {text}
      </Text>
      {endIcon ? (
        React.isValidElement(endIcon) ? (
          endIcon
        ) : (
          <Image
            source={endIcon as ImageSourcePropType}
            height={sizes.sm}
            width={sizes.sm}
          />
        )
      ) : null}
    </Button>
  );
};

export const LabeledIconButton = React.memo(_LabeledIconButton);
