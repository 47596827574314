import { useCallback, useEffect, useState } from 'react';
import {
  emailRegex,
  nameRegex,
  passwordRegex,
} from '../../../../../design-system/constants/regex';

export interface RegistrationInputs {
  name: string;
  email: string;
  password: string;
  agreed: boolean;
}

export type RegistrationInputsError = Record<keyof RegistrationInputs, boolean>;

export const useRegisterScreenState = () => {
  const [formErrors, setFormErrors] = useState<RegistrationInputsError>({
    name: false,
    email: false,
    password: false,
    agreed: false,
  });
  const [formData, setFormData] = useState<RegistrationInputs>({
    name: '',
    email: '',
    password: '',
    agreed: false,
  });

  const onFormDataChange = useCallback(
    (value: Partial<RegistrationInputs>) => {
      setFormData(state => ({ ...state, ...value }));
    },
    [setFormData],
  );

  useEffect(() => {
    setFormErrors(state => ({
      ...state,
      name: nameRegex.test(formData.name),
      email: emailRegex.test(formData.email),
      password: passwordRegex.test(formData.password),
      agreed: formData.agreed,
    }));
  }, [formData, setFormErrors]);

  return {
    formData,
    formErrors,
    onFormDataChange,
  };
};
