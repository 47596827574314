import {
  ApolloClient as Client,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GRAPHQL_API_URL } from '@env';

const getApolloLink = async (getToken: () => Promise<string | null>) => {
  const apiUrl =
    process.env.INTEGRATION_TEST_URL ||
    GRAPHQL_API_URL ||
    'http://localhost:3000/graphql';

  const authLink = setContext(async (_, { headers }) => {
    const token = await getToken();

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const httpLink = createHttpLink({
    uri: apiUrl,
    credentials: 'include',
  });

  const link = ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (networkError) {
        console.error(`[Network error]: ${networkError}`);
      }
      console.error(graphQLErrors);
    }),
    authLink,
    httpLink,
  ]);

  return link;
};

export class ApolloClient {
  private static instance: Client<NormalizedCacheObject> | undefined;

  private static getToken: () => Promise<string | null>;

  private constructor(getToken: () => Promise<string | null>) {
    ApolloClient.getToken = getToken;
  }

  public static async getInstance(): Promise<Client<NormalizedCacheObject>> {
    if (!this.getToken) {
      throw new Error('cannot initialize client without knowing how to get token');
    }

    if (!ApolloClient.instance) {
      const link = await getApolloLink(ApolloClient.getToken);
      ApolloClient.instance = new Client({
        link,
        cache: new InMemoryCache(),
      });
    }
    return ApolloClient.instance;
  }

  public static setGetToken(getToken: () => Promise<string | null>): void {
    ApolloClient.getToken = getToken;
  }

  static reset(): void {
    ApolloClient.instance = undefined;
  }
}
