import { AppThunk } from '../../../store/reduxStore';
import { LoginUserCommand } from '../../models/commands/loginUserCommand';
import {
  setAuthentication,
  setAuthenticationError,
  setLoadingAuthentication,
} from '../../reducers/authenticationPerformer.reducer';

export const loginUser =
  (credentials: LoginUserCommand): AppThunk =>
  async (dispatch, _getState, dependencies) => {
    dispatch(setLoadingAuthentication());

    try {
      const user = await dependencies.authenticationGateway.loginUser(credentials);
      await dependencies.localStorageProvider.setAuthenticatedUser(user);
      dispatch(setAuthentication(user));
    } catch (e) {
      dispatch(setAuthenticationError({ loginError: 'Invalid credentials' }));
    }
  };
