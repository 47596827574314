import React, { FC } from 'react';
import { View, StyleSheet, Dimensions } from 'react-native';
import Animated, {
  SlideInDown,
  interpolate,
  useAnimatedRef,
  useAnimatedStyle,
  useScrollViewOffset,
} from 'react-native-reanimated';
import { useTransparentHeader } from '../hooks/useTransparentHeader';

const { width } = Dimensions.get('window');
const IMG_HEIGHT = 300;

interface Props {
  imageUri: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
  headerRight?: React.ReactNode;
}

const _ImageHeaderTemplate: FC<Props> = ({ imageUri, children, footer, headerRight }) => {
  const scrollRef = useAnimatedRef<Animated.ScrollView>();
  const scrollOffset = useScrollViewOffset(scrollRef);
  useTransparentHeader({ bgContentHeight: IMG_HEIGHT, scrollOffset, headerRight });

  const imageAnimatedStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateY: interpolate(
            scrollOffset.value,
            [-IMG_HEIGHT, 0, IMG_HEIGHT, IMG_HEIGHT],
            [-IMG_HEIGHT / 2, 0, IMG_HEIGHT * 0.75],
          ),
        },
        {
          scale: interpolate(scrollOffset.value, [-IMG_HEIGHT, 0, IMG_HEIGHT], [2, 1, 1]),
        },
      ],
    };
  });

  return (
    <View style={styles.container}>
      <Animated.ScrollView
        contentContainerStyle={{ paddingBottom: 100 }}
        ref={scrollRef}
        scrollEventThrottle={16}
      >
        <Animated.Image
          source={{ uri: imageUri }}
          style={[styles.image, imageAnimatedStyle]}
          resizeMode="cover"
        />
        <View style={styles.content}>{children}</View>
      </Animated.ScrollView>
      <Animated.View entering={SlideInDown.delay(200)} style={styles.footer}>
        {footer}
      </Animated.View>
    </View>
  );
};

export const ImageHeaderTemplate = React.memo(_ImageHeaderTemplate);

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: '#fff' },
  image: { height: IMG_HEIGHT, width },
  content: { backgroundColor: '#fff', padding: 20 },
  footer: { padding: 20 },
});
