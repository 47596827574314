import { VehicleSpecGateway } from '../../../../../domain/gateways/vehicleSpecGateway';
import { VehicleSpec } from '../../../../../domain/models/vehicle/vehicleSpec';
import { VehicleSpecAdapter } from '../adapters/vehicleSpecAdapter';
import { VehicleSpecResponse } from '../types/graphqlVehicleSpecGateway.types';
import { ApolloClient } from './apolloClient';
import { VEHICLE_SPECS } from './queries/vehicleSpecs.query';

export class GraphqlVehicleSpecGateway implements VehicleSpecGateway {
  async retrieveAll(): Promise<VehicleSpec[]> {
    try {
      const client = await ApolloClient.getInstance();
      const { data } = await client.query<{
        vehicleSpecs: VehicleSpecResponse[];
      }>({ query: VEHICLE_SPECS });

      return data?.vehicleSpecs?.map(VehicleSpecAdapter.fromVehicleSpecResponse) ?? [];
    } catch (error) {
      throw new Error(`Failed to retrieve vehicle specs: ${JSON.stringify(error)}`);
    }
  }
}
