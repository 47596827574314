import React, { FC } from 'react';
import { ImageSourcePropType } from 'react-native';
import { BackButton, Image, Text } from '../../design-system/atoms';
import { useTheme } from '../theme/useTheme';

interface Props {
  height?: number;
  image?: ImageSourcePropType;
  text?: string;
  withBackButton?: boolean;
  children?: React.ReactNode;
}

const _ImageHeader: FC<Props> = ({ height, image, text, withBackButton, children }) => {
  const { assets, sizes } = useTheme();

  return (
    <Image
      background
      resizeMode="cover"
      padding={sizes.sm}
      radius={sizes.cardRadius}
      source={image || assets.background}
      height={height || sizes.height * 0.3}
    >
      {withBackButton && <BackButton />}

      {text && (
        <Text h4 center white marginBottom={sizes.md}>
          {text}
        </Text>
      )}
      {children}
    </Image>
  );
};

export const ImageHeader = React.memo(_ImageHeader);
