import { AppThunk } from '../../../store/reduxStore';
import { setAuthentication } from '../../reducers/authenticationPerformer.reducer';
import { logoutUser } from './logoutUser';

export const retrieveLoggedInUser =
  (): AppThunk => async (dispatch, _getState, dependencies) => {
    const authenticatedUser =
      await dependencies.localStorageProvider.getAuthenticatedUser();

    if (!authenticatedUser) {
      return;
    }

    try {
      // FIXME: it may take time to load user, so for now we show login screen for a while
      const user = await dependencies.authenticationGateway.retrieveLoggedInUser();
      dispatch(setAuthentication(user));
    } catch (error) {
      dispatch(logoutUser());
    }
  };
