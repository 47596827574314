import { City } from '../../../models/city';

export const citiesMock: City[] = [
  {
    id: 'cc195ed4-a6f1-4dc9-bfa5-7ceddbe73b6a',
    name: 'casablanca',
  },
  {
    id: 'be8a37e0-c4d8-4c48-8062-fb90273f33f',
    name: 'rabat',
  },
  {
    id: 'be8a37e0-c4d8-4c48-8062-fb90273f33f2',
    name: 'TanGer',
  },
  {
    id: 'be8a37e0-c4d8-4c48-8062-fb90273f33f3',
    name: 'Fes',
  },
  {
    id: 'be8a37e0-c4d8-4c48-8062-fb90273f33f4',
    name: 'Marrakech',
  },
  {
    id: 'be8a37e0-c4d8-4c48-8062-fb90273f33f5',
    name: 'agadir',
  },
  {
    id: 'be8a37e0-c4d8-4c48-8062-fb90273f33f5',
    name: 'beni melal',
  },
];
