import React, { useCallback } from 'react';
import { ItemWithId } from '../types/itemWithId';
import { SelectItem, SelectTemplate } from './SelectTemplate';
import { LabeledCheckbox } from './LabeledCheckbox';
import { useTranslation } from '../theme/useTranslation';

interface Props<T> {
  placeholder: string;
  options: SelectItem<T>[];
  selectedValues: T[];
  onSelectValue: (newValues: T[]) => void;
  danger?: boolean;
  disabled?: boolean;
}

const _MultiSelect = <T extends ItemWithId>({
  placeholder,
  options,
  selectedValues,
  onSelectValue,
  danger,
  disabled,
}: Props<T>) => {
  const { t } = useTranslation();

  const isOptionSelected = useCallback(
    (value: T) => selectedValues.some(_ => _.id === value.id),
    [selectedValues],
  );

  const handleSelectValue = useCallback(
    (value: T) => {
      const newValues = isOptionSelected(value)
        ? selectedValues.filter(_ => _.id !== value.id)
        : [...selectedValues, value];
      onSelectValue(newValues);
    },
    [isOptionSelected, selectedValues, onSelectValue],
  );

  return (
    <SelectTemplate
      label={
        selectedValues.length
          ? t(`common.selected`, { count: selectedValues.length })
          : null
      }
      placeholder={placeholder}
      options={options}
      onSelectValue={handleSelectValue}
      danger={danger}
      disabled={disabled}
      itemRenderer={_ => (
        <LabeledCheckbox
          key={_.label}
          transformText="capitalize"
          checked={isOptionSelected(_.value)}
          onChange={() => handleSelectValue(_.value)}
          label={_.label}
        />
      )}
    />
  );
};

export const MultiSelect = React.memo(_MultiSelect) as typeof _MultiSelect;
