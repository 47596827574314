import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoginPage } from '../../../../design-system/pages';
import { selectLoginUser } from '../../view-models/login-user/loginUserVM.selectors';
import { AppDispatch } from '../../../../store/reduxStore';
import { useGoogleAuth } from '../hooks/useGoogleAuth';
import { useLoginScreenState } from '../hooks/login-screen/useLoginScreen.state';
import { loginUser } from '../../../../domain/use-cases/authentication/loginUser';

const _LoginScreen = () => {
  const dispatch = useDispatch<AppDispatch>();
  const loginUserVM = useSelector(selectLoginUser);

  const { handleGoogleAuth } = useGoogleAuth();
  const { formData, onFormDataChange, formErrors } = useLoginScreenState();

  const onLogin = useCallback(async () => {
    dispatch(loginUser({ email: formData.email, password: formData.password }));
  }, [dispatch, formData]);

  return (
    <LoginPage
      loginUserVM={loginUserVM}
      formData={formData}
      formErrors={formErrors}
      onFormDataChange={onFormDataChange}
      onLogin={onLogin}
      onGoogleSignIn={handleGoogleAuth}
    />
  );
};

export const LoginScreen = React.memo(_LoginScreen);
