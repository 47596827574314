import React, { FC } from 'react';
import { useNavigation } from '@react-navigation/native';
import { Block, Chip, Text, PrimaryButton, Image } from '../atoms';
import { ItemCard } from './ItemCard';
import { useTheme } from '../theme/useTheme';
import { useTranslation } from '../theme/useTranslation';
import { useInAppBrowser } from '../../ports/primary/ui/hooks/useInAppBrowser';

interface Props {
  id: string;
  publishedAt: string;
  image: string;
  title: string;
  fuel?: string;
  horsePower?: string;
  kilometers?: string;
  price?: string;
  link: string;
  isFeatured: boolean;
}

const _AnnouncementCard: FC<Props> = ({
  id,
  publishedAt,
  image,
  title,
  fuel,
  horsePower,
  kilometers,
  price,
  link,
  isFeatured,
}) => {
  const { sizes, assets } = useTheme();
  const { t } = useTranslation();
  const { openLink } = useInAppBrowser();
  const { navigate } = useNavigation();

  const chipInfos = {
    fuel,
    kilometers,
    horsePower: `${horsePower} ${t('announcement.horsePower')}`,
  };

  return (
    <Block
      position="relative"
      flex={1}
      onPress={() => navigate('AnnouncementDetail', { announcementId: id })}
    >
      {isFeatured && (
        <Block position="absolute" top={-10} left={-10} style={{ zIndex: 100 }}>
          <Image
            source={assets.crown}
            style={{ width: 30, height: 20, transform: [{ rotate: '315deg' }] }}
          />
        </Block>
      )}
      <ItemCard subtitle={publishedAt} image={image} type="fullImageWidth">
        <Block gap={sizes.sm}>
          <Text h5>{title}</Text>

          <Block row align="center" gap={sizes.s}>
            {Object.entries(chipInfos).map(([key, value]) => {
              return value ? <Chip key={key} text={value} /> : null;
            })}
          </Block>

          <Block row justify="space-between" align="center">
            <Text h4>{price ?? t('announcement.priceNotAvailable')}</Text>
            <PrimaryButton
              minHeight={0}
              onPress={openLink(link)}
              text={t('announcement.accessOffer')}
            />
          </Block>
        </Block>
      </ItemCard>
    </Block>
  );
};

export const AnnouncementCard = React.memo(_AnnouncementCard);
