export interface AuthenticatedUser {
  user: CurrentUser | null;
  token: string;
}

interface CurrentUser {
  id: string;
  name: string;
  email: string;
  picture?: string;
  isEmailVerified: boolean;
  isNotificationsEnabled: boolean;
}

export const UNAUTHORIZED_USER_ERROR = 'UNAUTHORIZED_USER_ERROR';
