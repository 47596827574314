import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppState } from '../../store/appState';
import { setAuthentication } from './authenticationPerformer.reducer';

const initialState: AppState['notification'] = {
  token: null,
};

const _setNotificationToken = (
  state: AppState['notification'],
  action: PayloadAction<string>,
) => {
  state.token = action.payload;
};

const notificationSlice = createSlice({
  name: 'notificationToken',
  initialState,
  reducers: {
    setNotificationToken: _setNotificationToken,
  },
  extraReducers(builder) {
    builder.addCase(setAuthentication, (state, action) => {
      if (action.payload === null) {
        return initialState;
      }
      return state;
    });
  },
});

export const { setNotificationToken } = notificationSlice.actions;

export const { reducer: notificationReducer } = notificationSlice;
