import { useCallback, useEffect, useState } from 'react';
import { emailRegex, passwordRegex } from '../../../../../design-system/constants/regex';

export interface LoginInputs {
  email: string;
  password: string;
}

export type LoginInputsError = Record<keyof LoginInputs, boolean>;

export const useLoginScreenState = () => {
  const [formErrors, setFormErrors] = useState<LoginInputsError>({
    email: false,
    password: false,
  });

  const [formData, setFormData] = useState<LoginInputs>({
    email: '',
    password: '',
  });

  const onFormDataChange = useCallback(
    (value: Partial<LoginInputs>) => {
      setFormData(state => ({ ...state, ...value }));
    },
    [setFormData],
  );

  useEffect(() => {
    setFormErrors(state => ({
      ...state,
      email: emailRegex.test(formData.email),
      password: passwordRegex.test(formData.password),
    }));
  }, [formData, setFormErrors]);

  return { formData, formErrors, onFormDataChange };
};
