import React, { useCallback, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIsFocused } from '@react-navigation/native';
import { HomePage } from '../../../../design-system/pages';
import { retrieveAnnouncements } from '../../../../domain/use-cases/announcements-retrieval/retrieveAnnouncements';
import { AppDispatch } from '../../../../store/reduxStore';
import { selectAnnouncements } from '../../view-models/announcement/announcementVM.selectors';

const _HomeScreen = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isFocused = useIsFocused();

  const announcementsVM = useSelector(selectAnnouncements);

  const onRefreshList = useCallback(() => {
    dispatch(retrieveAnnouncements());
  }, [dispatch]);

  useLayoutEffect(() => {
    if (isFocused) {
      dispatch(retrieveAnnouncements());
    }
  }, [dispatch, isFocused]);

  return <HomePage onRefresh={onRefreshList} announcementsVM={announcementsVM} />;
};

export const HomeScreen = React.memo(_HomeScreen);
