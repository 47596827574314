import { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { NotificationData } from '../../types/NotificationData';

export const useClickOnNotification = () => {
  const { navigate } = useNavigation();

  const handleNotificationClick = useCallback(
    (data: NotificationData) => {
      const { announcementId } = data;
      if (announcementId) {
        navigate('AnnouncementDetail', { announcementId });
      }
    },
    [navigate],
  );

  return { handleNotificationClick };
};
