import {
  OAuthProvider,
  OAuthProviderName,
} from '../../../../../domain/gateways/providers/oAuthProvider';
import { ExternalUser } from '../../../../../domain/models/externalUser';

export class HttpOAuthProvider implements OAuthProvider {
  async getUser(token: string, provider: OAuthProviderName): Promise<ExternalUser> {
    switch (provider) {
      case OAuthProviderName.GOOGLE:
        return this.getGoogleUser(token);
      case OAuthProviderName.FACEBOOK:
        throw new Error('Not implemented');
    }
  }

  private async getGoogleUser(token: string): Promise<ExternalUser> {
    try {
      const response = await fetch('https://www.googleapis.com/userinfo/v2/me', {
        headers: { Authorization: `Bearer ${token}` },
      });
      const user = await response.json();

      return {
        email: user.email,
        name: user.name,
        picture: user.picture,
        externalId: user.id,
        isEmailVerified: user.verified_email,
      };
    } catch (error) {
      throw new Error(`Failed to fetch google user: ${error}`);
    }
  }
}
