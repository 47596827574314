import { AuthenticatedUser } from '../../../../../domain/models/authenticatedUser';
import { LoginUserResponse } from '../types/graphqlAuthenticationGateway.types';

export class AuthenticatedUserAdapter {
  static fromGraphqlResponse(response: LoginUserResponse): AuthenticatedUser {
    return {
      token: response.token,
      user: {
        id: response.id,
        name: response.name,
        email: response.email,
        isEmailVerified: response.isEmailVerified,
        isNotificationsEnabled: response.isNotificationsEnabled,
      },
    };
  }
}
