import { AppThunk } from '../../../store/reduxStore';
import { setLoadingCities, setCities } from '../../reducers/cities.reducer';

export const retrieveCities =
  (): AppThunk => async (dispatch, getState, dependencies) => {
    if (getState().cities.cities !== null) {
      return;
    }

    const cachedCities = await dependencies.cacheProvider.getCities();

    if (cachedCities) {
      dispatch(setCities(cachedCities));
    } else {
      dispatch(setLoadingCities());
    }

    try {
      const cities = await dependencies.cityGateway.retrieveAll();

      await dependencies.cacheProvider.setCities(cities);

      dispatch(setCities(cities));
    } catch (error) {
      console.error(error);
    }
  };
