import { AlertGateway } from '../../../../../domain/gateways/alertGateway';
import { Alert } from '../../../../../domain/models/alert';
import { AlertAdapter } from '../adapters/alertAdapter';
import { MyAlertsResponse } from '../types/graphqlAlertGateway.types';
import { ApolloClient } from './apolloClient';
import { SAVE_ALERTS } from './mutations/saveAlerts.mutation';
import { MY_ALERTS } from './queries/myAlerts.query';

export class GraphqlAlertGateway implements AlertGateway {
  async retrieveCreatedAlerts(): Promise<Alert[]> {
    const client = await ApolloClient.getInstance();
    const { data } = await client.mutate<{
      myAlerts: MyAlertsResponse[] | null;
    }>({ mutation: MY_ALERTS });

    return data?.myAlerts?.map(AlertAdapter.fromMyAlertsResponse) ?? [];
  }

  async saveAlerts(alerts: Alert[]): Promise<boolean> {
    try {
      const client = await ApolloClient.getInstance();
      const { data } = await client.mutate<{
        saveAlerts: boolean | null;
      }>({
        mutation: SAVE_ALERTS,
        variables: { alerts: alerts.map(AlertAdapter.toCreateAlertRequest) },
      });

      return data?.saveAlerts ?? false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }
}
