import { CityGateway } from '../../../../domain/gateways/cityGateway';
import { City } from '../../../../domain/models/city';
import { inMemoryLoading } from './inMemoryLoading';

export class InMemoryCityGateway implements CityGateway {
  private _cities: City[] = [];

  private _itShouldFail = false;

  async retrieveAll(): Promise<City[]> {
    await inMemoryLoading();

    if (this._itShouldFail) {
      throw new Error('error');
    }

    return this._cities;
  }

  set itShouldFail(value: boolean) {
    this._itShouldFail = value;
  }

  feedWith(...cities: City[]): void {
    this._cities = cities;
  }
}
