import { AppThunk } from '../../../store/reduxStore';
import { Alert } from '../../models/alert';
import {
  setLoadingSavingAlerts,
  setSaveAlertsError,
  setSaveAlertsSuccess,
} from '../../reducers/alerts.reducer';

export const saveAlerts =
  (alerts: Alert[]): AppThunk =>
  async (dispatch, _getState, dependencies) => {
    dispatch(setLoadingSavingAlerts());
    try {
      await dependencies.alertGateway.saveAlerts(alerts);
      dispatch(setSaveAlertsSuccess(alerts));
    } catch {
      dispatch(setSaveAlertsError());
    }
  };
