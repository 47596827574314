import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppState } from '../../store/appState';
import { VehicleSpec } from '../models/vehicle/vehicleSpec';

const initialState: AppState['vehicleSpecs'] = {
  vehicleSpecs: null,
  isLoading: false,
  error: null,
};

const _setVehicleSpecs = (
  state: AppState['vehicleSpecs'],
  action: PayloadAction<VehicleSpec[]>,
) => {
  state.isLoading = false;
  state.vehicleSpecs = action.payload;
};

const _setLoadingVehicleSpecs = (state: AppState['vehicleSpecs']) => {
  state.isLoading = true;
};

const vehicleSpecsSlice = createSlice({
  name: 'vehicleSpecs',
  initialState,
  reducers: {
    setLoadingVehicleSpecs: _setLoadingVehicleSpecs,
    setVehicleSpecs: _setVehicleSpecs,
  },
});

export const { setVehicleSpecs, setLoadingVehicleSpecs } = vehicleSpecsSlice.actions;

export const { reducer: vehicleSpecsReducer } = vehicleSpecsSlice;
