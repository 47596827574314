import React, { FC, useCallback, useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from '../theme/useTranslation';
import { useTheme } from '../theme/useTheme';
import { Block, PrimaryButton, Text } from '../atoms';
import { AnnouncementCard } from '../organisms';
import { List } from '../molecules';
import { AnnouncementVM } from '../../ports/primary/view-models/announcement/announcementVM';
import { BadgeLoader } from '../atoms/loaders/BadgeLoader';

interface Props {
  announcementsVM: AnnouncementVM;
  onRefresh: () => void;
}

const _HomePage: FC<Props> = ({ announcementsVM, onRefresh }) => {
  const { t } = useTranslation();
  const {
    colors,
    sizes,
    assets: { providerLogos },
  } = useTheme();
  const { navigate } = useNavigation();

  const goToAlertsScreen = useCallback(() => {
    navigate('MyAlerts');
  }, [navigate]);

  const Loader = useMemo(
    () => (
      <Block
        row
        wrap="wrap"
        justify="center"
        align="center"
        gap={sizes.padding}
        padding={sizes.padding}
      >
        {Object.values(providerLogos).map((_, index) => (
          <BadgeLoader key={index} badge={_} />
        ))}
      </Block>
    ),
    [providerLogos, sizes.padding],
  );

  return (
    <Block flex={1} color={colors.background}>
      {/* announcements list */}
      {announcementsVM && announcementsVM.announcements.length ? (
        <List
          loading={announcementsVM.isLoading}
          loader={Loader}
          data={announcementsVM.announcements}
          emptyList=""
          px={sizes.padding}
          py={sizes.padding}
          renderItem={({ item }) => <AnnouncementCard {...item} />}
          onRefresh={onRefresh}
        />
      ) : (
        <Block gap={sizes.m} padding={sizes.padding} flex={1}>
          <Text semibold>{t('announcements.emptyList')}</Text>
          <Block>
            <PrimaryButton
              fullWidth
              outlined
              text={t('home.createAlerts')}
              onPress={goToAlertsScreen}
            />
          </Block>
        </Block>
      )}
    </Block>
  );
};

export const HomePage = React.memo(_HomePage);
