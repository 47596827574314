import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppState } from '../../store/appState';
import { Alert } from '../models/alert';
import { setAuthentication } from './authenticationPerformer.reducer';

const initialState: AppState['alerts'] = {
  createdAlerts: null,
  isLoading: false,
  saving: {
    isLoading: false,
    success: null,
  },
};

const _setLoadingCreatedAlerts = (state: AppState['alerts']) => {
  state.isLoading = true;
};

const _setCreatedAlerts = (state: AppState['alerts'], action: PayloadAction<Alert[]>) => {
  state.createdAlerts = action.payload;
  state.isLoading = false;
};

const _setLoadingSavingAlerts = (state: AppState['alerts']) => {
  state.saving.isLoading = true;
  state.saving.success = null;
};

const _setSaveAlertsSuccess = (
  state: AppState['alerts'],
  action: PayloadAction<Alert[]>,
) => {
  state.saving.isLoading = false;
  state.saving.success = true;
  state.createdAlerts = action.payload;
};

const _setSaveAlertsError = (state: AppState['alerts']) => {
  state.saving.isLoading = false;
  state.saving.success = false;
};

const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    setLoadingCreatedAlerts: _setLoadingCreatedAlerts,
    setCreatedAlerts: _setCreatedAlerts,
    setLoadingSavingAlerts: _setLoadingSavingAlerts,
    setSaveAlertsSuccess: _setSaveAlertsSuccess,
    setSaveAlertsError: _setSaveAlertsError,
  },
  extraReducers(builder) {
    builder.addCase(setAuthentication, (state, action) => {
      if (action.payload === null) {
        return initialState;
      }
      return state;
    });
  },
});

export const {
  setLoadingCreatedAlerts,
  setCreatedAlerts,
  setLoadingSavingAlerts,
  setSaveAlertsSuccess,
  setSaveAlertsError,
} = alertsSlice.actions;

export const { reducer: alertsReducer } = alertsSlice;
