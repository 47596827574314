import React, { useEffect, useRef } from 'react';
import { Animated, Platform, StyleSheet } from 'react-native';

import {
  useDrawerStatus,
  createDrawerNavigator,
  DrawerContentComponentProps,
} from '@react-navigation/drawer';

import { useTheme } from '../../../../design-system/theme/useTheme';
import { Screens } from './Screens';
import { Block } from '../../../../design-system/atoms';
import { DrawerMenuContent } from '../../../../design-system/organisms';

const Drawer = createDrawerNavigator();

/* drawer menu screens navigation */
const ScreensStack = () => {
  const { colors } = useTheme();
  const isDrawerOpen = useDrawerStatus() === 'open';
  const animation = useRef(new Animated.Value(0)).current;

  const scale = animation.interpolate({
    inputRange: [0, 1],
    outputRange: [1, 0.88],
  });

  const borderRadius = animation.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 16],
  });

  const animatedStyle = {
    borderRadius,
    ...(Platform.OS === 'web'
      ? {}
      : {
          overflow: 'hidden' as const,
        }),
    transform: [{ scale }],
  };

  useEffect(() => {
    Animated.timing(animation, {
      duration: 200,
      useNativeDriver: true,
      toValue: isDrawerOpen ? 1 : 0,
    }).start();
  }, [isDrawerOpen, animation]);

  return (
    <Animated.View
      style={StyleSheet.flatten([
        animatedStyle,
        {
          flex: 1,
          borderColor: colors.card,
          borderWidth: isDrawerOpen ? 1 : 0,
        },
      ])}
    >
      <Screens />
    </Animated.View>
  );
};

/* drawer menu navigation */
export const Menu = () => {
  const { gradients } = useTheme();

  const drawerContent = (props: DrawerContentComponentProps) => (
    <DrawerMenuContent {...props} />
  );

  return (
    <Block flex={1} gradient={gradients.light}>
      <Drawer.Navigator
        drawerContent={drawerContent}
        screenOptions={{
          drawerType: 'slide',
          overlayColor: 'transparent',
          sceneContainerStyle: { backgroundColor: 'transparent' },
          drawerStyle: { width: '60%', backgroundColor: 'transparent' },
          headerShown: false,
        }}
      >
        <Drawer.Screen name="Screens" component={ScreensStack} />
      </Drawer.Navigator>
    </Block>
  );
};
