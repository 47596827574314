import * as SecureStore from 'expo-secure-store';
import { LocalStorageProvider } from '../../../../domain/gateways/localStorageProvider';
import { AuthenticatedUser } from '../../../../domain/models/authenticatedUser';

export class MobileLocalStorageProvider implements LocalStorageProvider {
  async setAuthenticatedUser(credentials: AuthenticatedUser): Promise<void> {
    await SecureStore.setItemAsync('authenticatedUser', JSON.stringify(credentials));
  }

  async getAuthenticatedUser(): Promise<AuthenticatedUser | null> {
    const user = await SecureStore.getItemAsync('authenticatedUser');

    if (!user) return null;

    return JSON.parse(user);
  }

  async removeAuthenticatedUser(): Promise<void> {
    await SecureStore.deleteItemAsync('authenticatedUser');
  }
}
