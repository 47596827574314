import React, { FC } from 'react';
import { ColorValue, Share } from 'react-native';
import { IconButton } from './IconButton';
import { useTheme } from '../../theme/useTheme';

interface Props {
  shareUrl: string;
  shareName?: string;
  color?: ColorValue;
}

const _ShareButton: FC<Props> = ({ shareUrl, shareName, color }) => {
  const { icons } = useTheme();

  const share = async () => {
    try {
      await Share.share({ title: shareName, url: shareUrl });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <IconButton
      shadow={false}
      withBorder={false}
      onPress={share}
      icon={icons.search}
      color={color}
    />
  );
};

export const ShareButton = React.memo(_ShareButton);
