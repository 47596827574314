import { AuthenticatedUser } from '../../../models/authenticatedUser';
import { registerUserCommandMock } from './registerUserCommandMock';

export const authenticatedUserMock: AuthenticatedUser = {
  token: registerUserCommandMock.email,
  user: {
    id: registerUserCommandMock.email,
    email: registerUserCommandMock.email,
    name: registerUserCommandMock.name,
    isEmailVerified: true,
    isNotificationsEnabled: true,
  },
};
