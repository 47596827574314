import React, { FC } from 'react';
import { FontAwesome5 } from '@expo/vector-icons';
import { ColorValue } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useTheme } from '../../theme/useTheme';
import { LabeledIconButton } from './LabeledIconButton';
import { useTranslation } from '../../theme/useTranslation';

interface Props {
  color?: ColorValue;
}

const _BackButton: FC<Props> = ({ color }) => {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const navigation = useNavigation();

  const _color = color || colors.white;

  return (
    <LabeledIconButton
      icon={<FontAwesome5 name="chevron-left" size={18} color={_color} />}
      text={t('common.goBack')}
      textColor={_color}
      justify="flex-start"
      onPress={() => navigation.goBack()}
    />
  );
};

export const BackButton = React.memo(_BackButton);
