import React, { FC } from 'react';
import { ImageSourcePropType, Platform } from 'react-native';
import { Button } from './Button';
import { Image } from '../Image';
import { useTheme } from '../../theme/useTheme';
import { IButtonProps } from '../../constants/types';

const isAndroid = Platform.OS === 'android';

interface Props extends IButtonProps {
  icon: ImageSourcePropType;
  size?: number;
  onPress?: () => void;
}

const _IconButton: FC<Props> = ({ icon, onPress, size, ...rest }) => {
  const { sizes } = useTheme();

  const _size = size || sizes.m;

  return (
    <Button
      minWidth={_size * 2}
      minHeight={_size * 2}
      outlined
      gray
      shadow={!isAndroid}
      onPress={onPress}
      {...rest}
    >
      <Image source={icon} height={_size} width={_size} color={rest.color} />
    </Button>
  );
};

export const IconButton = React.memo(_IconButton);
