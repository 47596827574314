import { AppThunk } from '../../../store/reduxStore';
import { RegisterUserCommand } from '../../models/commands/registerUserCommand';
import {
  setAuthenticationError,
  setLoadingAuthentication,
} from '../../reducers/authenticationPerformer.reducer';
import { loginUser } from './loginUser';

export const registerUser = (credentials: RegisterUserCommand): AppThunk => {
  return async (dispatch, _getState, dependencies) => {
    dispatch(setLoadingAuthentication());

    try {
      await dependencies.authenticationGateway.registerUser(credentials);
      await dispatch(
        loginUser({ email: credentials.email, password: credentials.password }),
      );
    } catch (e) {
      dispatch(setAuthenticationError({ registerError: 'User already registered' }));
    }
  };
};
