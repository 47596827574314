import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../../../../store/appState';
import { RegisterUserVM } from './registerUserVM';
import { AppSelector } from '../../../../store/reduxStore';

const selector = (state: AppState) => state.authentication;

export const selectRegisterUser: AppSelector<RegisterUserVM> = createSelector(
  [selector],
  (authentication: AppState['authentication']) => ({
    isLoading: authentication.isLoading,
  }),
);
