import { AppThunk } from '../../../store/reduxStore';
import { setCreatedAlerts, setLoadingCreatedAlerts } from '../../reducers/alerts.reducer';

export const retrieveCreatedAlerts =
  (): AppThunk => async (dispatch, _getState, dependencies) => {
    dispatch(setLoadingCreatedAlerts());

    const createdAlerts = await dependencies.alertGateway.retrieveCreatedAlerts();

    dispatch(setCreatedAlerts(createdAlerts));
  };
