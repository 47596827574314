import { Alert } from '../../../domain/models/alert';
import { CreateAlertInput } from '../ui/types/CreateAlertInput';

export class AlertAdapter {
  static toDomain(createAlertInputs: CreateAlertInput[]): Alert[] {
    return createAlertInputs.reduce<Alert[]>((acc, _) => {
      const alert = AlertAdapter.toDomainAlert(_);
      return alert ? [...acc, alert] : acc;
    }, []);
  }

  private static toDomainAlert(alertInput: CreateAlertInput): Alert | null {
    return alertInput.maxBudget !== null && alertInput.minBudget !== null
      ? {
          id: alertInput.id,
          city: alertInput.city,
          cityRadius: alertInput.cityRadius,
          vehicleMake: alertInput.vehicleMake,
          vehicleModels: alertInput.vehicleModels,
          minBudget: alertInput.minBudget,
          maxBudget: alertInput.maxBudget,
        }
      : null;
  }
}
