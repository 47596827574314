import React, { FC } from 'react';
import {
  ImageSourcePropType,
  StyleProp,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';
import { useTheme } from '../theme/useTheme';
import { Text, Image, Block } from '../atoms';

interface Props {
  icon: ImageSourcePropType;
  text?: string;
  onPress: () => void;
  style?: StyleProp<ViewStyle>;
}

const _Badge: FC<Props> = ({ icon, text, onPress, style }) => {
  const { colors, gradients, sizes } = useTheme();
  const bubbleDimensions = text ? sizes.sm : sizes.s;

  return (
    <TouchableOpacity style={style} onPress={onPress}>
      <Image source={icon} radius={0} color={colors.icon} />
      <Block
        padding={0}
        justify="center"
        position="absolute"
        gradient={gradients.primary}
        right={text ? -sizes.s : 0}
        top={text ? -sizes.s : 0}
        width={bubbleDimensions}
        height={bubbleDimensions}
        radius={bubbleDimensions / 2}
      >
        {text && (
          <Text white center bold size={10} lineHeight={10} paddingTop={3}>
            3
          </Text>
        )}
      </Block>
    </TouchableOpacity>
  );
};

export const Badge = React.memo(_Badge);
