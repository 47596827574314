import AsyncStorage from '@react-native-async-storage/async-storage';
import { VehicleSpec } from '../../../../domain/models/vehicle/vehicleSpec';
import { CacheProvider } from '../../../../domain/gateways/providers/cacheProvider';
import { City } from '../../../../domain/models/city';

export class MobileCacheProvider implements CacheProvider {
  async setVehicleSpecs(vehicleSpecs: VehicleSpec[]): Promise<void> {
    await AsyncStorage.setItem('vehicleSpecs', JSON.stringify(vehicleSpecs));
  }

  async getVehicleSpecs(): Promise<VehicleSpec[] | undefined> {
    const vehicleSpecs = await AsyncStorage.getItem('vehicleSpecs');
    return vehicleSpecs ? JSON.parse(vehicleSpecs) : undefined;
  }

  async setCities(cities: City[]): Promise<void> {
    await AsyncStorage.setItem('cities', JSON.stringify(cities));
  }

  async getCities(): Promise<City[] | undefined> {
    const cities = await AsyncStorage.getItem('cities');
    return cities ? JSON.parse(cities) : undefined;
  }
}
