import React, { FC } from 'react';
import { ImageSourcePropType, TouchableOpacity } from 'react-native';
import { useTheme } from '../../theme/useTheme';
import { Block } from '../Block';
import { Image } from '../Image';
import { Text } from '../Text';

interface Props {
  color?: string;
  text: string;
  onPress?: () => void;
  icon?: ImageSourcePropType;
}

const _TextButton: FC<Props> = ({ text, color, icon, onPress }) => {
  const { colors, sizes } = useTheme();

  return (
    <TouchableOpacity onPress={onPress}>
      <Block row align="center">
        <Text
          p
          color={color || colors.link}
          semibold
          size={sizes.linkSize}
          marginRight={sizes.s}
        >
          {text}
        </Text>
        {icon && <Image source={icon} color={color} />}
      </Block>
    </TouchableOpacity>
  );
};

export const TextButton = React.memo(_TextButton);
